import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input, Form, message } from 'antd';
import RichTextEditor from '../../Editor/RichTextEditor';
import styles from './EmailConfigurationModal.module.scss';
import AddNewEmailProvider from './AddNewEmailProvider';
import ExistingEmailProviders from './ExistingEmailProviders';
import { emailValidator } from '../../../Utils/Validators';
import placeholder from '../../Placeholders/PlaceholdersMessages';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const oAuth2 = 'OAuth2';

const FormItem = Form.Item;

export const EmailSignature = props => {
  const { value, onChange, intl } = props;
  const onUpdateSiganture = ({ htmlContent }) => {
    onChange(htmlContent);
  };
  return (
    <RichTextEditor
      editorContent={{ htmlContent: value }}
      showToolbar
      placeholder={intl.formatMessage({ ...placeholder.AddSignatureLabel })}
      onChange={onUpdateSiganture}
      className={styles.signatureInput}
    />
  );
};

const validateEmailWithProviderEmail = (email, callback, emailProviders, providerId) => {
  const providerConfig = emailProviders.find(emailProvider => emailProvider.Id === providerId);
  const authScheme = providerConfig?.AuthScheme;
  if (authScheme === oAuth2) {
    const providerEmail = providerConfig.Configuration.Email;
    if (email?.toLowerCase().trim() === providerEmail?.toLowerCase().trim()) callback();
    else callback(<FormattedMessage {...messages.emailIsNotPartofEmailProviderDomainLabel} />);
  }
  callback();
};

export const validateConfiguredEmail = (rule, value, callback, props, providerId) => {
  const { userEmails, emailProviders = [], currentEmailConfiguration } = props;
  const existingEmails = (userEmails || [])
    .map(email => email.EmailId.toLowerCase().trim())
    .filter(x => x !== currentEmailConfiguration.EmailId?.toLowerCase()?.trim());
  if (existingEmails.includes(value.toLowerCase().trim())) {
    callback(<FormattedMessage {...messages.pleaseInputValidEmailWhichIsNotAddedAlreadyLabel} />);
  }
  validateEmailWithProviderEmail(value, callback, emailProviders, providerId);
};

export const validateSenderEmail = (rule, value, callback, props, providerId) => {
  const fieldValue = value && value.trim();
  if (!fieldValue) {
    callback(<FormattedMessage {...messages.enterSenderEmailLabel} />);
  } else if (!emailValidator(fieldValue)) {
    callback(<FormattedMessage {...messages.pleaseInputvalidEmailLabel} />);
  } else validateConfiguredEmail(rule, value, callback, props, providerId);
};
export const validateEmailProvider = (rule, value, callback, isDefaultEmailConfiguration) => {
  if (!value && !isDefaultEmailConfiguration) callback(<FormattedMessage {...messages.pleaseSelectAnEmailProvider} />);
  else callback();
};
function EmailConfigurationModalContent(props) {
  const {
    orgDefaultEmail,
    form,
    currentEmailConfiguration,
    setCurrentEmailConfiguration,
    emailProviders = [],
    emailSmtpConfiguration,
    addNewEmailAddressFlag,
    deleteEmailConfig,
    isDefaultEmailConfiguration,
    intl,
    SetNotification,
  } = props;
  const providerId = form.getFieldValue('providerId');

  const setCurrentEmailConfigId = currentProviderId => {
    form.setFieldsValue({ providerId: currentProviderId });
  };

  React.useEffect(() => {
    if (form.isFieldsTouched()) form.validateFields(['email'], { force: true });
  }, [providerId]);

  React.useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const handleDeleteEmailProvider = async (_providerId, event) => {
    event.preventDefault();
    try {
      const currentProviderId = form.getFieldValue('providerId');
      await deleteEmailConfig(_providerId, { isAdmin: false });
      if (_providerId === currentProviderId) {
        setCurrentEmailConfiguration({ ...currentEmailConfiguration, ProviderConfiguration: {} });
        const manualProvider = emailProviders.find(provider => provider.ProviderName?.toLowerCase() === 'manual');
        form.setFieldsValue({ providerId: manualProvider?.Id });
      }
      SetNotification('SUCCESS', {
        messageId: 'deletedSuccessfully',
      })
      form.validateFieldsAndScroll({ force: true });
    } catch (error) {
      SetNotification('ERROR', {
        messageId: 'oopsSomethingJustWentWrong',
      })
    }
  };

  return (
    <>
      <div className={styles.emailConfigurationModalContent}>
        <FormItem label={<span><FormattedMessage {...placeholder.fromEmailLabel} /></span>} colon={false} required>
          {form.getFieldDecorator('email', {
            initialValue: currentEmailConfiguration.EmailId,
            rules: [
              { validator: (rule, value, callback) => validateSenderEmail(rule, value, callback, props, providerId) },
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ ...placeholder.enterEmailLabel })}
              className={styles.emailConfigurationInput}
              disabled={!addNewEmailAddressFlag}
            />
          )}
        </FormItem>
        <FormItem label={<span><FormattedMessage {...placeholder.fromNameLabel} /></span>} colon={false}>
          {form.getFieldDecorator('senderName', {
            initialValue: currentEmailConfiguration.SenderName,
          })(
            <Input
              placeholder={intl.formatMessage({ ...placeholder.enterNameLabel })}
              className={styles.emailConfigurationInput}
              disabled={currentEmailConfiguration.EmailId === orgDefaultEmail.EmailId}
            />
          )}
        </FormItem>
      </div>
      <FormItem label={<FormattedMessage {...placeholder.emailSignatureLabel} />} colon={false}>
        {form.getFieldDecorator('emailSignature', {
          initialValue: currentEmailConfiguration.Signature,
        })(<EmailSignature intl={intl} />)}
      </FormItem>

      <FormItem colon={false} required>
        {form.getFieldDecorator('providerId', {
          initialValue: currentEmailConfiguration.ProviderConfiguration?.Id,
          rules: [
            {
              required: true,
              validator: (rule, value, callback) =>
                validateEmailProvider(rule, value, callback, isDefaultEmailConfiguration),
            },
          ],
        })(
          <ExistingEmailProviders
            emailProviders={emailProviders}
            isorgDefaultEmail={orgDefaultEmail.EmailId === currentEmailConfiguration.EmailId}
            currentEmailProvider={currentEmailConfiguration.ProviderConfiguration}
            handleDeleteEmailProvider={handleDeleteEmailProvider}
          />
        )}
      </FormItem>

      <AddNewEmailProvider
        isorgDefaultEmail={orgDefaultEmail.EmailId === currentEmailConfiguration.EmailId}
        emailSmtpConfiguration={emailSmtpConfiguration}
        setCurrentEmailConfigId={setCurrentEmailConfigId}
      />
    </>
  );
}

export default injectIntl(EmailConfigurationModalContent);
export { EmailConfigurationModalContent as EmailConfigurationModalContentWithoutInjectIntl };
