import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form, Button, Icon, Popover, Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import BaseTemplateForm from '../BaseTemplateForm';
import { parseHtmlStringFromEditor } from '../../../../Utils/DomParserUtils';
import EmptyTemplate from '../../../ReactEmailEditor/EmptyTemplate.json';
import { validateTemplate } from '../../../../Utils/Validators';
import './CreateEmailTemplate.scss';
import eventTypes from '../../../../Analytics/EventTypes';
import EmailSubjectAndBodyWrapper from './EmailSubjectAndBodyWrapper';
import { getPreHeaderValidationStatus } from '../../../../Utils/EmailUtils/EmailUtils';
import { MergeTagIcon } from '../../../../Icons/AryaIcons';
import message from '../../../../Containers/ManualSearchV2/ManualSearchMessages';

const ckeditor = 'CKEditor';
const reactEmailEditor = 'ReactEmailEditor';
const reactQuill = 'ReactQuill';

function createRequestBody({ values, initialValues }) {
  return {
    Name: values['template-name'],
    Subject: values['template-subject'],
    PreHeader: values['template-preHeader'],
    Body:
      values.editorUsed?.toLowerCase() === 'reactemaileditor'
        ? values['template-body']
        : parseHtmlStringFromEditor(values['template-body']),
    IsBotEnabled: values['template-chatbot'],
    EditorUsed: values.editorUsed,
    Design: values['template-design'],
    IsPublic: initialValues?.IsPublic ?? false,
  };
}

class CreateEmailTemplate extends BaseTemplateForm {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
      preHeader: null,
      htmlToolActiveStatus: false,
      preHeaderVisibility: false,
    };
    this.myRef = React.createRef();
    this.preHeaderRef = React.createRef();
    this.editorRef = React.createRef();
    this.updateBodyContent = this.updateBodyContent.bind(this);
    this.testTemplate = this.testTemplate.bind(this);
    this.updateSubjectContent = this.updateSubjectContent.bind(this);
    this.addTags = this.addTags.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { initialValues = {} } = props;
    if (!state.body && initialValues.Body) {
      const {
        Body: body,
        Subject: subject,
        Design: design,
        EditorUsed: editorUsed,
        PreHeader: preHeader,
      } = initialValues;
      return {
        ...state,
        body,
        preHeader,
        preHeaderVisibility: preHeader ?? false,
        subject,
        design: design ? JSON.parse(design) : null,
        isAdvancedTemplate: editorUsed?.toLowerCase() === 'reactemaileditor',
      };
    }

    return state;
  }

  componentWillUnmount() {
    this.setState({
      body: null,
    });
  }

  addTags(tag) {
    this.myRef.current.addTags(tag);
  }

  addPreHeaderTags = tag => {
    this.preHeaderRef.current.addTags(tag);
  };

  getEditorUsed = () => {
    const { systemEmailTemplate, isSystemEmailWriteEnabled } = this.props;
    const { isAdvancedTemplate } = this.state;
    if (systemEmailTemplate && isSystemEmailWriteEnabled) return ckeditor;
    if (isAdvancedTemplate) return reactEmailEditor;
    return reactQuill;
  };

  validateEmailTemplate = () => {
    const { subject, body, plainText, preHeader } = this.state;
    const { systemEmailTemplate, mergeTags } = this.props;
    const editorUsed = this.getEditorUsed();
    const subjectMergeTags = !systemEmailTemplate
      ? mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'))
      : [];
    const mergeTagScope = systemEmailTemplate ? 'WhiteGlove' : 'EmailBody';
    const emailBodyContent = editorUsed === reactEmailEditor ? plainText : body;
    const bodyMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes(mergeTagScope));
    const preHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));
    return validateTemplate({
      subject,
      body: emailBodyContent,
      subjectMergeTags,
      bodyMergeTags,
      preHeader,
      preHeaderMergeTags,
    });
  };

  saveTemplate = event => {
    const { form, onSubmit, initialValues, setInvalidTemplateNotification } = this.props;
    const { body, design, preHeader } = this.state;
    const editorUsed = this.getEditorUsed();
    event.preventDefault();
    if (editorUsed !== ckeditor && !this.validateEmailTemplate()) {
      setInvalidTemplateNotification();
      return;
    }
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(
          createRequestBody({
            values: {
              ...values,
              'template-body': body,
              'template-preHeader': preHeader,
              'template-subject': this.myRef.current.quillRef.getText().replace(/\n$/gi, ''),
              editorUsed,
              'template-design': design ? JSON.stringify(design) : null,
            },
            initialValues,
          })
        );
        form.resetFields();
      }
    });
  };

  updateBodyContent({ htmlContent }) {
    this.setState({
      body: htmlContent,
    });
  }

  updateCKeditorBodyContent = content => {
    this.setState({
      body: content,
    });
  };

  updateAdvancedHtmlContent = ({ html, design }) => {
    this.editorRef.current.editor.exportPlainText(data => {
      const { text } = data;
      this.setState({ body: html, design, plainText: text });
    });
  };

  onClearAdvancedHtmlContent = () => {
    this.editorRef.current.loadDesign(EmptyTemplate.design);
    this.setState({ body: null, design: EmptyTemplate.design });
  };

  updateSubjectContent({ htmlContent }) {
    this.setState({
      subject: htmlContent,
    });
  }

  updatePreHeaderContent = ({ htmlContent }) => {
    this.setState({
      preHeader: htmlContent,
    });
  };

  setHtmlToolActiveStatus = status => {
    this.setState({
      htmlToolActiveStatus: status,
    });
  };

  testTemplate() {
    const { testEmailTemplate, setInvalidTemplateNotification } = this.props;
    const { body, isAdvancedTemplate } = this.state;
    const parsedBodyContent = isAdvancedTemplate ? body : parseHtmlStringFromEditor(body);
    const subject = this.myRef.current.quillRef.getText().replace(/\n$/gi, '');
    if (!this.validateEmailTemplate()) {
      setInvalidTemplateNotification();
      return;
    }
    testEmailTemplate(subject, parsedBodyContent);
  }

  toogleAdvancedEditor = () => {
    const { isAdvancedTemplate } = this.state;
    this.setState({ isAdvancedTemplate: !isAdvancedTemplate, body: null, design: null });
  };

  addPreHeaderToEmail = () => {
    this.setState({
      preHeaderVisibility: true,
    });
  };

  removePreHeaderFromEmail = () => {
    this.setState({
      preHeader: undefined,
      preHeaderVisibility: false,
    });
  };

  render() {
    const {
      title,
      onClose,
      visible,
      initialValues,
      mergeTags,
      testTemplateStatus,
      viewOnly,
      systemEmailTemplate,
      isEditTemplateDrawer,
      isReactEmailEditorEnabled,
      intl,
    } = this.props;
    let emailSubjectMergeTags = [];
    if (!systemEmailTemplate) {
      emailSubjectMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
    }
    const emailPreHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));
    const { body, subject, htmlToolActiveStatus, isAdvancedTemplate, design, preHeader, preHeaderVisibility } =
      this.state;
    let buttonText = <FormattedMessage {...message.testEmailLabel} />;
    if (testTemplateStatus === 'INPROGRESS') {
      buttonText = <FormattedMessage {...message.testingLabel} />;
    } else if (testTemplateStatus === 'COMPLETED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="success-icon" type="check-circle" />
          </div>
          <div className="test-email-text">
            {' '}
            <FormattedMessage {...message.testEmailLabel} />{' '}
          </div>
        </div>
      );
    } else if (testTemplateStatus === 'FAILED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="failed-icon" type="close-circle" theme="filled" />
          </div>
          <div className="test-email-text">
            <FormattedMessage {...message.testEmailLabel} />
          </div>
        </div>
      );
    }

    const emailSubjectPopoverContent = (
      <div className="tags-popover-content">
        {emailSubjectMergeTags.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => {
              this.addTags(mergeTag);
            }}
            role="presentation"
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const emailPreHeaderPopoverContent = (
      <div className="tags-popover-content">
        {emailPreHeaderMergeTags.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => {
              this.addPreHeaderTags(mergeTag);
            }}
            role="presentation"
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const mergeTagsClassname = classNames('create-template-merge-tag-button', 'create-email-template-merge-tag-button');

    const addEmailSubjectMergeTags = (
      <Popover content={emailSubjectPopoverContent} placement="left" trigger="click">
        <Tooltip zIndex={2223} title={<FormattedMessage {...message.mergeTagsLabel} />}>
          <span className={mergeTagsClassname}>
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    );

    const addEmailPreHeaderMergeTags = (
      <Popover content={emailPreHeaderPopoverContent} placement="left" trigger="click">
        <Tooltip zIndex={2223} title={<FormattedMessage {...message.mergeTagsLabel} />}>
          <span className={mergeTagsClassname}>
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    );

    const regex = /\n$/gi;
    const regexEmptyHtml = /(<p><br><\/p>)+$/gi; // to remove HTML tag
    let isEnabled = false;

    if (this.myRef.current) {
      isEnabled =
        this.myRef.current.quillRef.getText().trim().replace(regex, '').length !== 0 &&
        body &&
        !getPreHeaderValidationStatus(this.preHeaderRef) &&
        body.trim().replace(regexEmptyHtml, '').length !== 0 &&
        !htmlToolActiveStatus;
    }
    const isTestBtnEnabled = (!this.myRef.current && initialValues.Subject && initialValues.Body) || isEnabled;
    return (
      <Drawer
        className="create-template email drawer"
        destroyOnClose
        title={title}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={isAdvancedTemplate && !viewOnly ? 1078 : 650}
      >
        <Form onSubmit={this.saveTemplate}>
          <div className="row-one">
            {this.getTemplateNameItem(
              intl.formatMessage({ ...message.emailTemplateNameLabel }),
              intl.formatMessage({ ...message.pleaseInputTheTemplateNameLabel }),
              initialValues.Name || initialValues.Key,
              systemEmailTemplate
            )}

            {!viewOnly ? (
              <Tooltip
                placement="topRight"
                title={
                  <div>
                    <FormattedMessage {...message.selectTestEmailToHaveThisMessageSentToYourLabel} /> <br />
                    <FormattedMessage {...message.theTestEmailWillBeSentEmailLabel} />
                    <br /> <FormattedMessage {...message.listedInYourProfileLabel} />
                  </div>
                }
              >
                <Button
                  shape="round"
                  className="create-template test"
                  onClick={this.testTemplate}
                  loading={testTemplateStatus === 'INPROGRESS'}
                  disabled={!isTestBtnEnabled}
                >
                  {buttonText}
                </Button>
              </Tooltip>
            ) : null}
          </div>
          <EmailSubjectAndBodyWrapper
            viewOnly={viewOnly}
            emailSubjectMergeTags={emailSubjectMergeTags}
            emailPreHeaderMergeTags={emailPreHeaderMergeTags}
            addEmailSubjectMergeTags={addEmailSubjectMergeTags}
            addEmailPreHeaderMergeTags={addEmailPreHeaderMergeTags}
            subject={subject}
            preHeader={preHeader}
            updateSubjectContent={this.updateSubjectContent}
            updatePreHeaderContent={this.updatePreHeaderContent}
            ref={{ myRef: this.myRef, editorRef: this.editorRef, preHeaderRef: this.preHeaderRef }}
            isAdvancedTemplate={isAdvancedTemplate}
            isEditTemplateDrawer={isEditTemplateDrawer}
            isReactEmailEditorEnabled={isReactEmailEditorEnabled}
            toogleAdvancedEditor={this.toogleAdvancedEditor}
            onClearAdvancedHtmlContent={this.onClearAdvancedHtmlContent}
            systemEmailTemplate={systemEmailTemplate}
            mergeTags={mergeTags}
            isReadonly={initialValues.IsReadonly}
            getEditorUsed={this.getEditorUsed}
            body={body}
            design={design}
            updateAdvancedHtmlContent={this.updateAdvancedHtmlContent}
            updateCKeditorBodyContent={this.updateCKeditorBodyContent}
            updateBodyContent={this.updateBodyContent}
            setHtmlToolActiveStatus={this.setHtmlToolActiveStatus}
            preHeaderVisibility={preHeaderVisibility}
            addPreHeaderToEmail={this.addPreHeaderToEmail}
            removePreHeaderFromEmail={this.removePreHeaderFromEmail}
            intl={intl}
          />
          <br />
          <div className="footer">
            {!viewOnly
              ? this.getSaveButton(
                  <FormattedMessage {...message.saveTemplateLabel} />,
                  !isEnabled,
                  eventTypes.connect.emailTemplates.save
                )
              : null}
            {this.getCancelButton(
              <FormattedMessage {...message.cancelLabel} />,
              onClose,
              eventTypes.connect.emailTemplates.cancel
            )}
          </div>
        </Form>
      </Drawer>
    );
  }
}

CreateEmailTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  emailTemplateName: PropTypes.string.isRequired,
  subjectLine: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

CreateEmailTemplate.defaultProps = {
  initialValues: {},
};

export default injectIntl(Form.create()(CreateEmailTemplate));
export { CreateEmailTemplate as CreateEmailTemplateWithoutForm };
