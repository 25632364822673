import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import styles from './AppliedSourceTag.module.scss';

export default function AppliedSourceTag(props) {
  const { sourceName, showViewApplicationButtonInDrawer, formattedPortalUrl } = props;
  let appliedTagText = 'Applied';
  const joinedText = ' via ';
  if (sourceName) {
    appliedTagText =
      sourceName === 'ChatBot' ? appliedTagText.concat(' via AryaBot') : appliedTagText.concat(joinedText, sourceName);
  }

  return (
    <div className={styles.appliedSourceTagWrapper}>
      <div className={classNames(styles.appliedSourceTag, styles.candidate360AppliedSourceTag)}>{appliedTagText}</div>
      {showViewApplicationButtonInDrawer && (
        <Button
          type="link"
          className={styles.applicationReviewBtn}
          onClick={e => {
            e.stopPropagation();
            window.open(formattedPortalUrl, '_blank');
          }}
        >
          View Application
        </Button>
      )}
    </div>
  );
}
