import { apiV3Client } from './BaseRepository';

export function fetchJobUsageBudget(jobId) {
  return apiV3Client.get(`job-usage-budget/${jobId}/available-budget`);
}
export function FetchAllJobUsageBudgetRequests(payload) {
  return apiV3Client.post(`job-usage-budget/requests`, { From: 0, Size: 10, ...payload });
}

export function postJobUsageBudgetRequest(jobId) {
  return apiV3Client.post(`job-usage-budget/request/${jobId}`, {});
}

export function updateJobUsageBudgetRequestStatus(requestId, payload) {
  return apiV3Client.put(`job-usage-budget/request/${requestId}`, payload);
}
