/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
  setfetchManualSearchCandidatesBySourceApiStatus,
  setAllTabCandidatesFetchApiStatus,
  setCandidatesBySource,
  setCandidatesCountBySource,
  setSecondsElapsedToFetchCandidatesBySource,
  setAllTabCandidateIds,
  setCandidatesFetchedCountBySource,
  setAllTabCandidatesTotalCount,
  clearManualSearchCandidates as _clearManualSearchCandidates,
  setCandidateGroups,
  setStoredCandidatesGroupInfo,
  setManualSearchNextPaginatedQueryBySources,
  setManualSearchAllTabNextPaginatedQuery,
  setInitialCandidatesCountBySource,
  setAllTabAggregationFilters,
  setAllTabSelectedCandidateFilters as _setAllTabSelectedCandidateFilters,
  clearAllTabFiltersInfo as _clearAllTabFiltersInfo,
  setAllTabAggregationStatus,
} from './ActionCreators/ManualSearchCandidateActionCreators';
import * as manualSearchRepository from '../Repository/ManualSearchRepository';
import { mergeCandidateData, getSourceName } from '../Utils/SourceUtils';
import { reorderManualSearchSources } from './ManualSearchActions';
import { sortDuplicateCandidates, getGroupHeadByCandidateId } from '../Utils/DeDuplicationUtils';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { getAllTabCandidatesAggregation, allowedDeDuplicationSources } from '../Utils/CandidateListUtils';
import {
  setCandidatesForDeDuplicationBySource,
  setCandidateGroupsBySource,
  setCandidateGroupsInfoBySource,
} from './ActionCreators/CandidateDeDuplicationActionCreators';
import { setCandidates } from './ActionCreators/CandidateActions';
import { isExactMatchSearch, getManualSearchCandidatesCountPerBatch } from '../Utils/ManualSearchUtils';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

export function generateDeDuplicationKey(candidate) {
  const { Name: candidateName } = candidate;
  const lowerCaseCandidateName = candidateName?.toLowerCase();
  const nameParts = lowerCaseCandidateName.split(' ');
  const firstName = nameParts?.length > 0 ? nameParts[0] : '';
  const lastName = nameParts?.length > 1 ? nameParts.slice(1).join(' ') : '';
  return firstName < lastName ? `${firstName} ${lastName}` : `${lastName} ${firstName}`;
}

export const getSortedGroupedCandidates = (allCandidates, candidatesArray, revealActiveChannelSourceName) => {
  Object.keys(candidatesArray).forEach(key => {
    candidatesArray[key].candidateIds = sortDuplicateCandidates(
      candidatesArray[key].candidateIds,
      allCandidates,
      revealActiveChannelSourceName
    );
    const { candidateIds } = candidatesArray[key];
    const [GroupHead] = candidateIds;
    candidatesArray[key].GroupHead = GroupHead;
  });

  return candidatesArray;
};

export const getShouldGroupCandidate = candidateName => {
  const trimmedCandidateName = candidateName?.trim();
  return trimmedCandidateName && trimmedCandidateName.split(' ').length > 1;
};

export function groupCandidates(storedCandidates, nonStoredCandidates, store, sourceName) {
  let groupedCandidates = {};
  Object.values(storedCandidates).forEach(candidate => {
    const shouldGroupCandidate = getShouldGroupCandidate(candidate.Name);
    if (shouldGroupCandidate) {
      const key = generateDeDuplicationKey(candidate);
      if (groupedCandidates[key]) {
        groupedCandidates[key].candidateIds.push(candidate.Id);
      } else {
        const groupId = key;
        groupedCandidates[key] = {
          GroupHead: candidate.Id,
          candidateIds: [candidate.Id],
          groupId,
        };
      }
    }
  });
  nonStoredCandidates.forEach(candidate => {
    const shouldGroupCandidate = getShouldGroupCandidate(candidate.Name);
    if (shouldGroupCandidate) {
      const key = generateDeDuplicationKey(candidate);
      if (groupedCandidates[key]) {
        groupedCandidates[key].candidateIds.push(candidate.Id);
      } else {
        const groupId = key;
        groupedCandidates[key] = {
          GroupHead: candidate.Id,
          candidateIds: [candidate.Id],
          groupId,
        };
      }
    }
  });
  groupedCandidates = Object.values(groupedCandidates);
  const groupedDuplicates = groupedCandidates.filter(candidate => candidate.candidateIds.length > 1);
  const groupedDuplicatesWithUuid = {};
  const storedCandidatesGroupInfo = groupedDuplicates.flatMap(candidate => {
    const nonNewCandidateIds = candidate.candidateIds.filter(
      candidateId => !(candidateId in Object.values(nonStoredCandidates).map(item => item.Id))
    );
    return nonNewCandidateIds.map(candidateId => {
      return {
        candidateId,
        CandidateGroup: candidate.groupId,
      };
    });
  });
  groupedDuplicates.forEach(candidate => {
    groupedDuplicatesWithUuid[candidate.groupId] = candidate;
    delete candidate.groupId;
  });
  nonStoredCandidates.forEach(candidate => {
    const candidateGroupId = Object.keys(groupedDuplicatesWithUuid).find(key =>
      groupedDuplicatesWithUuid[key].candidateIds.includes(candidate.Id)
    );
    if (candidateGroupId) {
      candidate.CandidateGroup = candidateGroupId;
    }
  });
  const featureToggleList = getFeatureToggleList(store);
  const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
  const allCandidates = [...nonStoredCandidates, ...Object.values(storedCandidates)];
  const candidateGroupInfo = getSortedGroupedCandidates(
    allCandidates,
    groupedDuplicatesWithUuid,
    revealActiveChannelSourceName
  );
  return { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo };
}

export const mergeCandidates = ({ candidates, storedCandidates, mergeProperty }) => {
  const mergedCandidates = [...storedCandidates];
  candidates.forEach(candidate => {
    const existingCandidate = mergedCandidates.find(
      storedCandidate => storedCandidate[mergeProperty] === candidate[mergeProperty]
    );
    if (existingCandidate) {
      Object.assign(existingCandidate, candidate);
    } else {
      mergedCandidates.push(candidate);
    }
  });
  return mergedCandidates;
};

export const getMergedDeDuplicatedCandidates = mergedCandidatesPayload => {
  const { candidates, candidateGroupInfo, storedCandidates = [] } = mergedCandidatesPayload;
  const totalCurrentCandidates = mergeCandidates({ candidates, storedCandidates, mergeProperty: 'Id' });
  const groupHeads = candidates.map(candidate => {
    return getGroupHeadByCandidateId(candidateGroupInfo, candidate.Id);
  });
  const uniqueGroupHeads = _.uniq(groupHeads);
  const groupHeadCandidates = uniqueGroupHeads.map((Id, index) => {
    return {
      ...totalCurrentCandidates.find(candidate => candidate.Id === Id),
      IsQuickSearchCandidate: true,
      candidateIndex: index,
    };
  });
  const filteredCandidates = candidates.filter(candidate => {
    return !groupHeadCandidates.some(groupCandidate => groupCandidate.Id === candidate.Id);
  });
  const nonGroupHeadCandidates = filteredCandidates.map(filteredCandidate => {
    return {
      ...totalCurrentCandidates.find(candidate => candidate.Id === filteredCandidate.Id),
      IsQuickSearchCandidate: true,
    };
  });
  const allCandidates = [...groupHeadCandidates, ...nonGroupHeadCandidates];
  return _.uniqBy(allCandidates, 'Id');
};

export const groupDuplicatesBySource = ({ candidates, jobId, source, shouldSetCandidates }) => {
  return async (dispatch, getState) => {
    const store = getState();
    const storedCandidates = store.CandidateDeDuplicationReducer.ByJobId[jobId]?.BySource?.[source] || [];
    const newCandidates = _.differenceBy(candidates, storedCandidates, 'Id');
    let updatedCandidates = candidates;
    if (newCandidates.length > 0) {
      const { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo } = groupCandidates(
        storedCandidates,
        newCandidates,
        store,
        source
      );
      dispatch(setCandidatesForDeDuplicationBySource({ jobId, candidates: nonStoredCandidates, sourceName: source }));
      dispatch(setCandidateGroupsBySource({ jobId, sourceName: source, candidateGroupInfo }));
      dispatch(setCandidateGroupsInfoBySource({ jobId, sourceName: source, storedCandidatesGroupInfo }));
      if (shouldSetCandidates) {
        const mergedCandidatesPayload = {
          candidates,
          candidateGroupInfo,
          storedCandidates,
        };
        updatedCandidates = getMergedDeDuplicatedCandidates(mergedCandidatesPayload);
      }
    } else if (shouldSetCandidates) {
      const candidateGroupBySource = store?.CandidateDeDuplicationReducer?.ByJobId?.[jobId]?.CandidateGroups ?? {};
      const candidateGroupInfo = candidateGroupBySource[source] || {};
      updatedCandidates = getMergedDeDuplicatedCandidates({
        candidates,
        candidateGroupInfo,
        storedCandidates,
      });
    }
    if (shouldSetCandidates) dispatch(setCandidates(updatedCandidates));
  };
};

// !! To do for All tab on next phase
// export function findMaxIncreasingCandidateIndex(arr, startIndex) {
//   if (!arr.includes(startIndex)) return -1;
//   let maxElement = startIndex;
//   while (arr.includes(maxElement)) maxElement += 1;
//   return maxElement;
// }
// export function shouldFetchCandidatesFromRemoteSource({ from, size, store, source, jobId }) {
//   const currentSourcedCandidates = store.ManualSearchCandidateReducer?.ByJobId[jobId]?.BySource?.[source] || {};
//   const currentSourcedCandidatesCount = currentSourcedCandidates?.TotalCount;

//   if (typeof currentSourcedCandidatesCount !== 'number') return true;
//   const currentSourcedCandidatesIndices = Object.values(currentSourcedCandidates.CandidatesIndexVsId).map(
//     item => item.Index
//   );
//   const maxCandidatesIndexToCheck = Math.min(currentSourcedCandidatesCount, from + size); // to handle the case where,sourced candidates count is less than max index, in that case it won't find the max index in candidate indices
//   return !(
//     currentSourcedCandidatesIndices.includes(maxCandidatesIndexToCheck - 1) &&
//     currentSourcedCandidatesIndices.includes(from)
//   );
// }

function fetchManualSearchAllTabCandidatesBySource({
  manualCriteria,
  jobId,
  isMore,
  allowReordering,
  allowSetFetchCandidateApiStatus,
  shouldSetInitialCandidatesCountBySource,
  isBackgroundCandidatesFetchCall,
}) {
  return async (dispatch, getState) => {
    let response;
    const store = getState();
    const source = getSourceName(manualCriteria.Sources[0]);
    try {
      if (allowSetFetchCandidateApiStatus)
        dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: INPROGRESS, source, jobId }));
      const start = Date.now();
      const featureToggleList = getFeatureToggleList(store);
      const isShowAllClientCandidateStatus = featureToggleList.ShowAllClientCandidateStatus.IsEnabled;
      if (isShowAllClientCandidateStatus) manualCriteria.ShowAllClientCandidateStatus = true;
      response = await manualSearchRepository.fetchManualSearchCandidates({
        criteria: manualCriteria,
        from: manualCriteria.From,
        size: manualCriteria.Size,
        jobId,
      });
      const secondsElapsed = Math.floor((Date.now() - start) / 1000);
      const responseData = response.data[0];
      const candidatesObject = responseData.Candidates || [];
      const count = responseData.Total || 0;
      const nextFrom = responseData?.NextPaginatedQuery?.From;
      const mergedcandidates = mergeCandidateData({ candidatesObject, isQuickSearchCandidate: true });
      const candidateAggregations = responseData.Aggregations;

      const manualSearchCandidates = store.ManualSearchCandidateReducer.ByJobId[jobId].CandidatesById || {};
      const { candidateGroupInfo, nonStoredCandidates, storedCandidatesGroupInfo } = groupCandidates(
        manualSearchCandidates,
        mergedcandidates,
        store
      );
      const currentPage = store.JobCandidatesTabReducer.currPage;
      const { activeSourceName } = store.JobCandidatesTabReducer;
      const candidates = nonStoredCandidates;
      if (allowedDeDuplicationSources.includes(source))
        dispatch(groupDuplicatesBySource({ candidates, jobId, source }));
      if (!_.isEmpty(candidateGroupInfo)) {
        dispatch(setCandidateGroups({ jobId, candidateGroupInfo }));
        dispatch(setStoredCandidatesGroupInfo({ jobId, storedCandidatesGroupInfo }));
      }
      dispatch(
        setCandidatesBySource({
          source,
          jobId,
          candidates,
          candidateAggregations,
          startIndex: manualCriteria.From ?? 0,
        })
      );
      if (!isBackgroundCandidatesFetchCall) dispatch(setCandidatesCountBySource({ source, jobId, count }));
      dispatch(setCandidatesFetchedCountBySource({ source, jobId, count: candidates.length, isMore }));
      dispatch(setSecondsElapsedToFetchCandidatesBySource({ source, jobId, secondsElapsed }));
      if (shouldSetInitialCandidatesCountBySource)
        dispatch(setInitialCandidatesCountBySource({ source, jobId, count }));
      if (allowSetFetchCandidateApiStatus)
        dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: COMPLETED, source, jobId }));
      if (allowReordering) dispatch(reorderManualSearchSources({ jobId }));
      if (isExactMatchSearch(manualCriteria)) {
        if (activeSourceName !== 'All' || currentPage === 1) {
          dispatch(
            setManualSearchNextPaginatedQueryBySources({
              jobId,
              source,
              nextFrom,
              currentPage,
            })
          );
        }
        if (activeSourceName === 'All' || currentPage === 1) {
          dispatch(
            setManualSearchAllTabNextPaginatedQuery({
              jobId,
              source,
              nextFrom,
            })
          );
        }
      }
    } catch (errorResponse) {
      dispatch(setfetchManualSearchCandidatesBySourceApiStatus({ status: FAILED, source, jobId }));
    }
  };
}

function fetchManualSearchAllTabCandidates({
  manualCriteria,
  batchesFetched,
  jobId,
  isMore,
  allowReordering,
  allowSetFetchCandidateApiStatus = true,
  shouldSetInitialCandidatesCountBySource,
  isBackgroundCandidatesFetchCall,
  showAggregationFilterLoader = true,
}) {
  return async (dispatch, getState) => {
    const sources = manualCriteria.Sources.filter(x => x.Portal !== 'All');
    try {
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      if (allowSetFetchCandidateApiStatus) dispatch(setAllTabCandidatesFetchApiStatus(INPROGRESS));
      const candidatesBySource = store.ManualSearchCandidateReducer.ByJobId?.[jobId]?.BySource;
      let nonExhaustedSources = [...sources];
      if (isBackgroundCandidatesFetchCall)
        nonExhaustedSources = sources.filter(source => {
          const sourceName = getSourceName(source);
          const totalCount = candidatesBySource[sourceName]?.TotalCount;
          const fetchedCount = candidatesBySource[sourceName]?.FetchedCount;
          return fetchedCount < totalCount;
        });
      if (!nonExhaustedSources.length) return;
      if (showAggregationFilterLoader) dispatch(setAllTabAggregationStatus(INPROGRESS));
      await Promise.all(
        nonExhaustedSources.map(async source => {
          const nextFrom = store.ManualSearchCandidateReducer.ByJobId[jobId]?.AllTabNextPageBySources
            ? store.ManualSearchCandidateReducer.ByJobId[jobId]?.AllTabNextPageBySources[getSourceName(source)] ?? 0
            : 0;
          const manualSearchCriteria = !isExactMatchSearch(manualCriteria)
            ? { ...manualCriteria }
            : { ...manualCriteria, From: nextFrom, Size: 10 };
          const isAdvanceSearchAliasSkillsEnabled = featureToggleList.AdvanceSearchAliasSkills.IsEnabled;
          const sourcename = getSourceName(source);
          const criteriaToUse =
            sourcename === 'Monster' && !isAdvanceSearchAliasSkillsEnabled
              ? {
                  ...manualCriteria,
                  Skills: {
                    ...manualCriteria.Skills,
                    Prefers: manualCriteria.Skills.Prefers.map(({ AliasSkills, ...rest }) => ({
                      ...rest,
                    })),
                  },
                }
              : manualSearchCriteria;
          await dispatch(
            fetchManualSearchAllTabCandidatesBySource({
              manualCriteria: { ...criteriaToUse, Sources: [source] },
              jobId,
              isMore,
              allowReordering,
              allowSetFetchCandidateApiStatus,
              shouldSetInitialCandidatesCountBySource,
              isBackgroundCandidatesFetchCall,
            })
          );
        })
      );
      const sourceNames = nonExhaustedSources.map(source => getSourceName(source));
      await dispatch(setAllTabCandidateIds({ jobId, sources: sourceNames, batchesFetched }));
      if (!isMore) {
        const latestStore = getState();
        const bySource = latestStore.ManualSearchCandidateReducer.ByJobId[jobId].BySource;
        let count = 0;
        const updatedBySource = Object.keys(bySource)
          .filter(key => key !== 'All')
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: bySource[key] });
          }, {});
        Object.values(updatedBySource).forEach(x => {
          count += x.TotalCount ?? 0;
        });
        await dispatch(setAllTabCandidatesTotalCount({ jobId, count }));
      }
      if (allowSetFetchCandidateApiStatus) await new Promise(resolve => setTimeout(resolve, 100));
      const isAllTabAggregationFilterEnabled = featureToggleList.AdvancedSearchAggregationFilters.IsEnabled;
      if (isAllTabAggregationFilterEnabled) {
        const exisintingCandidatesObject = store.ManualSearchCandidateReducer?.ByJobId?.[jobId].CandidatesById || {};
        const existingCandidatesIds = Object.values(exisintingCandidatesObject).map(candidate => candidate?.Id);
        const { aggregation, preferTitles, manualSearchSkills } = getAllTabCandidatesAggregation(
          getState,
          jobId,
          existingCandidatesIds
        );
        dispatch(setAllTabAggregationFilters({ jobId, aggregation, preferTitles, manualSearchSkills }));
      }
      if (allowSetFetchCandidateApiStatus) dispatch(setAllTabCandidatesFetchApiStatus(COMPLETED));
      if (showAggregationFilterLoader) dispatch(setAllTabAggregationStatus(COMPLETED));
    } catch (error) {
      console.log({ errorResponse: error });
      dispatch(setAllTabCandidatesFetchApiStatus('FAILED'));
      if (showAggregationFilterLoader) dispatch(setAllTabAggregationStatus(FAILED));
    }
  };
}

function fetchNextBatchManualSearchAllTabCandidates({ manualCriteria, jobId, allowSetFetchCandidateApiStatus }) {
  return (dispatch, getState) => {
    const store = getState();
    const batchesFetchedSoFar = _.get(store, ['ManualSearchCandidateReducer', 'ByJobId', jobId, 'BatchesFetched'], 0);
    const featureToggleList = getFeatureToggleList(store);
    const manualSearchCandidatesCountPerBatch = getManualSearchCandidatesCountPerBatch(
      featureToggleList.AdvancedSearchAggregationFilters.IsEnabled
    );
    dispatch(
      fetchManualSearchAllTabCandidates({
        manualCriteria: {
          ...manualCriteria,
          From: manualSearchCandidatesCountPerBatch * batchesFetchedSoFar,
          Size: manualSearchCandidatesCountPerBatch,
        },
        batchesFetched: batchesFetchedSoFar + 1,
        jobId,
        isMore: true,
        allowSetFetchCandidateApiStatus,
      })
    );
  };
}

function clearManualSearchCandidates({ jobId }) {
  return _clearManualSearchCandidates({ jobId });
}

const resetAllTabCandidatesFetchApiStatus = () => {
  return setAllTabCandidatesFetchApiStatus(undefined);
};

export const setAllTabSelectedCandidateFilters = ({ jobId, selectedFilters, keywordsOperation }) => {
  return _setAllTabSelectedCandidateFilters({ jobId, selectedFilters, keywordsOperation });
};

export const clearAllTabFiltersInfo = ({ jobId }) => {
  return _clearAllTabFiltersInfo({ jobId });
};

export {
  fetchManualSearchAllTabCandidatesBySource,
  fetchManualSearchAllTabCandidates,
  fetchNextBatchManualSearchAllTabCandidates,
  clearManualSearchCandidates,
  resetAllTabCandidatesFetchApiStatus,
};
