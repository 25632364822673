import _ from 'lodash';
import cleanSet from 'clean-set';

function MessageConversationsReducer(state = {}, action) {
  let newState;
  let messages;
  let pendingMessages;
  let messagesIds;
  let newMessage;
  switch (action.type) {
    case 'SET_CONVERSATION_MESSAGES':
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      if (action.payload.PhoneNumber) {
        messages = _.get(state, ['ById', action.payload.conversationId, action.payload.PhoneNumber], []);
        messagesIds = messages.map(message => message.Id);
        pendingMessages = _.get(
          state,
          ['Pending', 'ById', action.payload.conversationId, action.payload.PhoneNumber],
          []
        );
        pendingMessages = pendingMessages.filter(message => !messagesIds.includes(message.Id));
        pendingMessages = pendingMessages.map(message => ({ ...message, Status: 'Pending' }));
        _.setWith(
          newState,
          ['Pending', 'ById', action.payload.conversationId, action.payload.PhoneNumber],
          pendingMessages,
          Object
        );
        _.setWith(
          newState,
          ['ById', action.payload.conversationId, action.payload.PhoneNumber],
          action.payload.Messages,
          Object
        );
      }
      return newState;
    case 'SET_LATEST_MESSAGES': {
      const { conversationId } = action.payload;
      const messageObject = {};
      if (action.payload.userId) {
        const { messages: latestMessages } = action.payload;
        latestMessages.forEach(message => {
          const tempObj = {
            ...message.LatestMessage,
            unreadSmsCount: message.UnreadSmsCount,
          };
          messageObject[message.PhoneNumber] = [tempObj];
        });
      }
      newState = cleanSet(state, ['ById', conversationId], messageObject);
      return newState;
    }
    case 'SET_CONVERSATION_PENDING_MESSAGES':
      newState = _.cloneDeep(state);
      pendingMessages = _.get(
        newState,
        ['Pending', 'ById', action.payload.conversationId, action.payload.message.To],
        []
      );
      pendingMessages.unshift({ ...action.payload.message, Status: 'Pending' });
      _.setWith(
        newState,
        ['Pending', 'ById', action.payload.conversationId, action.payload.message.To],
        pendingMessages,
        Object
      );
      return newState;

    case 'ADD_NEW_MESSAGE':
      if (action.payload.Message.RequestStatus === 'Pending' && !action.payload?.isChatBotDemo) {
        return state;
      }
      newState = _.cloneDeep(state);
      if (!newState.ById) {
        newState.ById = {};
      }
      newMessage = action.payload.Message;
      if (newMessage.IsByPerson) {
        pendingMessages = _.get(
          state,
          ['Pending', 'ById', action.payload.conversationId, action.payload.Message.From],
          []
        );
        pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
        _.setWith(
          newState,
          ['Pending', 'ById', action.payload.conversationId, action.payload.Message.From],
          pendingMessages,
          Object
        );
        messages = _.get(state, ['ById', action.payload.conversationId, action.payload.Message.From], []);
        let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
        if (!unreadSmsCount) unreadSmsCount = 0;
        unreadSmsCount += 1;
        const messageWithUnreadSmsCount = {
          ...action.payload.Message,
          unreadSmsCount,
        };
        const receivedMessageGuid = action.payload.Message.Id;
        const index = messages.findIndex(message => message.Id === receivedMessageGuid);
        if (index !== -1) messages[index] = messageWithUnreadSmsCount;
        else messages.unshift(messageWithUnreadSmsCount);
        _.setWith(
          newState,
          ['ById', action.payload.conversationId, messageWithUnreadSmsCount.From],
          _.uniqBy(messages, 'Id'),
          Object
        );
      } else {
        pendingMessages = _.get(
          state,
          ['Pending', 'ById', action.payload.conversationId, action.payload.Message.To],
          []
        );
        pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
        _.setWith(
          newState,
          ['Pending', 'ById', action.payload.conversationId, action.payload.Message.To],
          pendingMessages,
          Object
        );
        messages = _.get(state, ['ById', action.payload.conversationId, action.payload.Message.To], []);
        let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
        if (!unreadSmsCount) unreadSmsCount = 0;
        const messageWithUnreadSmsCount = {
          ...action.payload.Message,
          unreadSmsCount,
        };
        messages.unshift(messageWithUnreadSmsCount);
        _.setWith(
          newState,
          ['ById', action.payload.conversationId, messageWithUnreadSmsCount.To],
          _.uniqBy(messages, 'Id'),
          Object
        );
      }
      return newState;
    default:
      return state;
  }
}

function getMessageConversations(state, conversationId) {
  const messages = _.cloneDeep(_.get(state.MessageConversationsReducer, ['ById', conversationId], {}));
  let pendingMessages = _.cloneDeep(_.get(state.MessageConversationsReducer, ['Pending', 'ById', conversationId], {}));
  pendingMessages = _.differenceBy(pendingMessages, messages, 'Id');
  return _.mergeWith(messages, pendingMessages, (objValue, srcValue) => {
    if (_.isArray(objValue) && _.isArray(srcValue)) {
      return [...srcValue, ...objValue];
    }
    return undefined;
  });
}

function getMessageConversationOfAllPhoneNumbers(state, conversationId) {
  if (state.MessageConversationsReducer.ById) return state.MessageConversationsReducer.ById[conversationId] || {};
  return {};
}

function getPendingMessageConversation(state, conversationId) {
  return state.MessageConversationsReducer?.Pending?.ById[conversationId] || {};
}

export {
  MessageConversationsReducer,
  getMessageConversations,
  getMessageConversationOfAllPhoneNumbers,
  getPendingMessageConversation,
};
