import React from 'react';
import { Button, Popover, DatePicker, Radio, Icon, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import styles from './BudgetInformation.module.scss';
import CreditsUsedFilter from '../../../../Icons/CreditsUsedFilterIcon';
import message from '../../../CandidateFilters/CandidateFiltersMessage';

const { RangePicker } = DatePicker;

const CreditFilterPopover = props => {
  const [filterTab, setFilterTab] = React.useState('date');
  const [visible, setVisible] = React.useState(false);
  const [filterActive, setFilterActive] = React.useState(false);
  const { form, FetchAllJobUsageBudgetRequests, setCurrentAppliedFilter, setPageNumber, currentAppliedFilter } = props;
  const { getFieldDecorator } = form;

  const onSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        setPageNumber(1);
        const payload = {};
        if (values.approval) {
          payload.JobUsageBudgetRequestStatus = [values.approval];
        }
        if (values.date?.length > 1) {
          const [fromDate, toDate] = values.date;
          payload.FromDate = fromDate.toISOString();
          payload.ToDate = toDate.toISOString();
        }
        const currentPayload = { ...currentAppliedFilter, ...payload };
        setCurrentAppliedFilter(currentPayload);
        if (Object.keys(payload).length > 0) {
          FetchAllJobUsageBudgetRequests(currentPayload);
        }
        setVisible(false);
        setFilterActive(true);
      }
    });
  };

  const handleVisibleChange = newVisible => {
    setVisible(newVisible);
    if (!newVisible) form.resetFields();
  };

  const onClear = () => {
    if (filterActive) {
      setPageNumber(1);
      FetchAllJobUsageBudgetRequests();
      setFilterActive(false);
      setCurrentAppliedFilter(null);
    }
    setVisible(false);
    form.resetFields();
  };

  const onClickShortcutDateMenu = (day, type) => {
    const updatedDate = [moment().subtract(day, type), moment()];
    form.setFieldsValue({ date: updatedDate });
  };
  const menuItems = [
    { tab: 'date', label: 'Date' },
    { tab: 'approval', label: 'Request Approval' },
  ];
  const renderMenuItem = (tab, label) => (
    <div
      className={classNames(styles.menuItem, { [styles.selectedMenuItem]: filterTab === tab })}
      role="presentation"
      onClick={() => setFilterTab(tab)}
    >
      {label}
      {filterTab === tab && (
        <span>
          <Icon type="right" style={{ color: '#808489' }} />
        </span>
      )}
    </div>
  );

  const approvalOptions = ['Approved', 'Rejected', 'Pending'];

  const creditFilterPopoverContent = () => (
    <Form>
      <div className={styles.creditFilterPopoverContainer}>
        <div className={styles.leftTab}>{menuItems.map(menuItem => renderMenuItem(menuItem.tab, menuItem.label))}</div>
        <div className={styles.rightTab}>
          <div className={classNames(styles.dateContainer, { [styles.hide]: filterTab !== 'date' })}>
            <div className={styles.date}>
              <Form.Item>
                {getFieldDecorator('date')(
                  <RangePicker
                    placeholder={['Select day', 'Select day']}
                    getCalendarContainer={node => node.parentNode}
                  />
                )}
              </Form.Item>
            </div>
            <div className={styles.dateShortcutKeys}>
              <p role="presentation" onClick={() => onClickShortcutDateMenu(1, 'days')}>
                Last 24 hours
              </p>
              <p role="presentation" onClick={() => onClickShortcutDateMenu(7, 'days')}>
                Last 1 week
              </p>
              <p role="presentation" onClick={() => onClickShortcutDateMenu(1, 'months')}>
                Last 1 month
              </p>
              <p role="presentation" onClick={() => onClickShortcutDateMenu(3, 'months')}>
                Last 3 months
              </p>
            </div>
          </div>
          <div className={classNames(styles.approvalContainer, { [styles.hide]: filterTab !== 'approval' })}>
            <Form.Item>
              {getFieldDecorator('approval')(
                <Radio.Group
                  onChange={e => form.setFieldsValue({ approval: e.target.value })}
                  className={styles.approvalContainer}
                >
                  {approvalOptions.map(option => (
                    <div
                      key={option}
                      className={classNames(styles.approvalItem, {
                        [styles.selectedApprovaItem]: form.getFieldValue('approval') === option,
                      })}
                    >
                      <Radio value={option}>{option}</Radio>
                    </div>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );

  const creditFilterPopoverTitle = () => (
    <div className={styles.creditFilterTitleContainer}>
      <div className={styles.creditInformationFilterTitle}>
        <FormattedMessage {...message.filtersLabel} />
      </div>
      <div>
        <Button shape="round" onClick={onClear}>
          <FormattedMessage {...message.clearButtonLabel} />
        </Button>
        <Button shape="round" className={styles.applyButton} htmlType="submit" type="primary" onClick={onSubmit}>
          <FormattedMessage {...message.applyButtonLabel} />
        </Button>
      </div>
    </div>
  );
  return (
    <Popover
      overlayClassName={styles.creditInfoFilter}
      content={creditFilterPopoverContent()}
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement="bottomRight"
      align={{ offset: [10, 0] }}
      title={creditFilterPopoverTitle()}
    >
      {filterActive && <div className={styles.dot}></div>}
      <CreditsUsedFilter style={{ cursor: 'pointer' }} />
    </Popover>
  );
};

export default CreditFilterPopover;
