import React from 'react';
import _ from 'lodash';
import {
  getCandidatePrimarySource,
  getInternalSourceWithCandidateId,
  getOriginalSource,
  getPushedAtsSource,
  getPortalName,
} from './SourceUtils';
import config from '../Config/Config';
import CandidateAssignedStatus from '../Components/CandidateStatus/CandidateAssignedStatus';
import AppliedSourceTag from '../Components/CandidateListItem/AppliedSourceTag/AppliedSourceTag';

export function getSourceData(candidate, version) {
  let atsSource;
  let pushedCandidateSource;
  let originalSource;
  if (version === 'ats') {
    atsSource = getInternalSourceWithCandidateId(candidate.Sources);
    originalSource = getOriginalSource(candidate.Sources);
  } else {
    pushedCandidateSource = getPushedAtsSource(candidate.Sources);
  }
  return {
    atsSource,
    pushedCandidateSource,
    originalSource,
  };
}

export function checkAccessConfig(candidate, atsConfig) {
  const accessConfiguration = atsConfig?.AccessConfiguration;
  const allowedPortalsToPush = _.get(atsConfig, ['AccessConfiguration', 'AllowedPortals']);
  const candidatePrimarySource = getCandidatePrimarySource(candidate.Sources);
  const isPushAllowed =
    _.get(atsConfig, ['AccessConfiguration', 'IsCreateCandidateAllowed'], false) &&
    _.get(atsConfig, ['AccessConfiguration', 'IsCreateCandidateManuallyAllowed'], false) &&
    (!allowedPortalsToPush ||
      allowedPortalsToPush.length === 0 ||
      allowedPortalsToPush.includes(
        _.get(candidatePrimarySource, ['Portal']) || _.get(candidatePrimarySource, ['Group'])
      ));
  const isRecreateCandidateAllowed = accessConfiguration?.IsRecreateCandidateAllowed;
  const isPushCandidateNotAllowedForAryaClosedJobs = accessConfiguration?.IsPushCandidateNotAllowedForAryaClosedJobs;
  const isManualCandidateCreationAllowedForApplied = accessConfiguration?.IsManualCandidateCreationAllowedForApplied;

  return {
    isPushAllowed,
    isRecreateCandidateAllowed,
    isPushCandidateNotAllowedForAryaClosedJobs,
    isManualCandidateCreationAllowedForApplied,
  };
}
export const getCandidateDrawerRedirectedTab = (
  candidate,
  revealActiveChannelSourceName,
  activeSourceName,
  tabName
) => {
  const allowedRedirectedTabs = ['profile', 'work-history'];
  if (!tabName || allowedRedirectedTabs.includes(tabName)) {
    const candidateSourcePortal = getPortalName(candidate.Sources, revealActiveChannelSourceName);
    const redirectTabCandidateSources = ['social', 'passive', 'linkedinadvance', 'indeed'];
    return redirectTabCandidateSources.includes(candidateSourcePortal?.toLowerCase()) ||
      redirectTabCandidateSources.includes(activeSourceName?.toLowerCase())
      ? 'work-history'
      : 'profile';
  }
  return tabName;
};
export const scrollToCandidate360Top = candidate360TabRef => {
  const candidate360TabElement = candidate360TabRef.current;
  if (candidate360TabElement)
    candidate360TabElement.scrollTo({
      top: 1, // Adding top as 1 because in case of 0 it will set the candidate drawer header visibility to true
      left: 0,
      behavior: 'smooth',
    });
};
export const getCandidateStatus = (candidate, featureToggleList, jobGuid, atsConfig, currentUser, userConfig = {}) => {
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const isApplicationViewEnabled = _.get(featureToggleList, ['ViewApplicationOnCandidateCard', 'IsEnabled'], false);
  const showViewApplicationButtonInDrawer = !(
    isPaidJobServiceEnabled ||
    !isApplicationViewEnabled ||
    !candidate?.AppliedTime ||
    !jobGuid
  );
  const impersonateId = localStorage.getItem('Impersonate') || null;
  const userCanImpersonate = !!(userConfig?.CanImpersonate || (impersonateId !== null && impersonateId !== currentUser?.sub));
  const portalUrl = config.urls.careerportalUrl;
  let currentUserKey = currentUser?.email;
  if (userCanImpersonate) {
    currentUserKey = `${currentUserKey};QM`;
  }
  const formattedPortalUrl = portalUrl
    ?.replace('{{jobId}}', jobGuid)
    .replace('{{candidateId}}', candidate.Guid)
    .replace('{{appliedTime}}', candidate.AppliedTime)
    .replace('{{rId}}', btoa(currentUserKey))
    .replace('{{aryaCandidateId}}', candidate.Id);
  const candidateOrigin = candidate?.CandidateOrigin ?? '';

  const showCandidateAppliedTag = candidate?.AppliedTime && !isPaidJobServiceEnabled;
  const showCandidateAssignedStatus = candidate?.AssignedStatus;
  if (showCandidateAssignedStatus) {
    return {
      candidateStatus: (
        <CandidateAssignedStatus assignedStatus={candidate.AssignedStatus} candidateOrigin={candidateOrigin} />
      ),
      statusTag: !_.isEmpty(atsConfig) ? candidate.AssignedStatus : null,
    };
  }
  if (showCandidateAppliedTag) {
    return {
      candidateStatus: (
        <AppliedSourceTag
          sourceName={candidateOrigin}
          showViewApplicationButtonInDrawer={showViewApplicationButtonInDrawer}
          formattedPortalUrl={formattedPortalUrl}
        />
      ),
      statusTag: !_.isEmpty(atsConfig) ? 'Applied' : null,
    };
  }
  return { candidateStatus: null };
};
export function getCandidateStatusFromSources(candidate) {
  const atsSource = getInternalSourceWithCandidateId(candidate.Sources);
  return atsSource?.Status;
}
