import React from 'react';
import { Icon } from 'antd';

export const JobUsageBudgetApprovedSVG = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3027 10.4245C17.6309 9.99086 17.8125 9.45961 17.8125 8.90688C17.8125 8.02992 17.3223 7.19984 16.5332 6.73695C16.3301 6.6178 16.0988 6.55509 15.8633 6.55531H11.1797L11.2969 4.15492C11.3242 3.57484 11.1191 3.02406 10.7207 2.60414C10.5252 2.39716 10.2893 2.23249 10.0276 2.12028C9.76588 2.00808 9.48395 1.95074 9.19922 1.9518C8.18359 1.9518 7.28516 2.63539 7.01562 3.61391L5.33789 9.68813H5.33203V18.0475H14.5566C14.7363 18.0475 14.9121 18.0123 15.0742 17.942C16.0039 17.5455 16.6035 16.6373 16.6035 15.6295C16.6035 15.3834 16.5684 15.1413 16.498 14.9069C16.8262 14.4733 17.0078 13.942 17.0078 13.3893C17.0078 13.1432 16.9727 12.901 16.9023 12.6666C17.2305 12.233 17.4121 11.7018 17.4121 11.1491C17.4082 10.903 17.373 10.6588 17.3027 10.4245ZM2.1875 10.3131V17.4225C2.1875 17.7682 2.4668 18.0475 2.8125 18.0475H4.08203V9.68813H2.8125C2.4668 9.68813 2.1875 9.96742 2.1875 10.3131Z"
        fill="#13C26B"
      />
    </svg>
  );
};

const JobUsageBudgetApproved = props => <Icon component={JobUsageBudgetApprovedSVG} {...props} />;

export default JobUsageBudgetApproved;
