import _ from 'lodash';
import PubSub from 'pubsub-js';
import * as AryaNotifyActions from '../Actions/AryaNotifyActions';
import { fetchConnectStatus } from '../Actions/ConnectStatusActions';
import { fetchConfig } from '../Actions/ConfigActions';
import store from '../store';
import { getConfig, getMaskingConfig } from '../Reducers/ConfigReducer';
import {
  getJobGuidToIdMapping,
  getJobGuidFromJobId,
  getCurrentJobId,
  getJobsById,
  getCurrentJobDetails,
} from '../Reducers/JobReducer';
import {
  getCandidateFetchContactStatus,
  getCandidates,
  getCurrentCandidateId,
  getCandidateDetails,
} from '../Reducers/CandidateReducer';
import * as connectRepository from '../Repository/ConnectRepository';
import * as OrderActions from '../Actions/OrderActions';
import { NOTIFICATION_RECEIVED } from '../PubSub/EventTypes';

import { fetchCandidateDetails, setBulkContactStatus } from '../Actions/CandidateActions';
import {
  updateCandidateRecommendedInfo,
  setCandidatePersonId,
  setCandidateConnectIds,
  setFetchContactStatus,
  addVaultCandidateIds,
  setSourcingNotification,
  setRecommendedStatusChangedNotification,
  setCandidateConnectionStatusByCandidateId,
  setBulkShortlistedNotification,
  setCandidatesPublishedNotification,
  setCandidateDownloadedDetails,
  setCandidateFavouriteStatus,
  setScoringStatus,
} from '../Actions/ActionCreators/CandidateActions';
import { fetchCandidateNotes, fetchCallNotes } from '../Actions/CandidateConnectActions';
import * as broadcastEventActions from '../Actions/BroadcastEventActions';
import { setPushCandidateAtsApiStatus } from '../Actions/AtsActions';
import { fetchJobDilatedIntel } from '../Actions/DilatedIntelActions';
import { setCandidatesRecommendationStatus } from '../Actions/JobCandidatesTabActions';
import { getIsNotifcationFetchEnabled } from '../Reducers/AryaNotifyReducer';
import { getManualSearchCandidates } from '../Reducers/ManualSearchCandidateReducer';
import * as announcementActionCreator from '../Actions/ActionCreators/AnnouncementActionCreator';
import { updateSourcingStats } from '../Actions/ActionCreators/JobActionCreator';
import {
  addReplyToTheThread,
  addThreadToTheChat,
  addUnreadCountForChat,
} from '../Actions/ActionCreators/SourcingSupportChatActionCreator';
import { checkConversationPopupVisiblity } from '../Reducers/MessagePopupReducer';
import { updateScoutingAgentStats } from '../Actions/ScoutingAgentActions';
import { fetchMailsByEmailConversationId } from '../Actions/ConnectActions';
import { saveCandidateStatus } from '../Utils/LocalStorageUtils';
import { getShouldNavigateToSubsegmentRedirectionTab } from '../Utils/CandidateListUtils';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { setNotification } from '../Actions/ActionCreators/ConnectActions';

function isCandidateDisplayedInList(currentCandidateId, jobId) {
  const currentStore = store.getState();
  const currentVisibleCandidateIds = Object.keys(getCandidates(currentStore, jobId));
  return currentVisibleCandidateIds.includes(currentCandidateId);
}

export const isCandidateExtraInfoExists = currentCandidateId => {
  const currentStore = store.getState();
  const extraInfoCandidateIds = Object.keys(currentStore.CandidateExtraInfoReducer.CandidatesById || {}) || [];
  return extraInfoCandidateIds.includes(currentCandidateId);
};

function fetchConnectStatuses(jobGuid, personIds) {
  return dispatch => {
    return connectRepository.fetchConnectStatuses(jobGuid, personIds).then(connectStatusResponse => {
      dispatch({
        type: 'SET_CANDIDATE_CONNECT_INFO',
        payload: {
          connectInfo: {
            ...connectStatusResponse.data.ConnectStatuses,
          },
        },
      });
    });
  };
}

const atsSource = 'LeoATS';

function getReplaceNotificationAction(source, payload) {
  if ((source || '').toUpperCase() === 'CONNECT' && payload) {
    return {
      type: 'REPLACE_CONNECT_NOTIFICATIONS',
      payload,
    };
    // eslint-disable-next-line no-else-return
  } else if (
    ((source || '').toUpperCase() === 'ARYA' || (source || '').toUpperCase() === atsSource.toUpperCase()) &&
    payload
  ) {
    return {
      type: 'REPLACE_ARYA_NOTIFICATIONS',
      payload,
    };
  }
  return null;
}

function fetchJobsOnEmailNotificationReceive(payload, dispatch) {
  const currentStore = store.getState();
  const notifications = payload.Notifications.filter(notification => notification.Type === 'Email');
  const notificationJobGuids = notifications.map(notification => notification.Payload.RefId);
  const uniqueNotificationJobGuids = [...new Set(notificationJobGuids)];
  const jobGuidToIdMapping = getJobGuidToIdMapping(currentStore);
  const jobGuids = new Set(Object.keys(jobGuidToIdMapping));
  const filteredJobGuids = uniqueNotificationJobGuids.filter(id => !jobGuids.has(id));
  if (filteredJobGuids?.length > 0)
    PubSub.publish(NOTIFICATION_RECEIVED, {
      filteredJobGuids,
      dispatch,
    });
}

function handleCandidateRecommendedNotification(dispatch, eventData) {
  const currentStore = store.getState();
  const currentJobId = getCurrentJobId(currentStore);
  const jobId = _.get(eventData, ['Payload', 'JobId']);
  if (!jobId || !currentJobId) {
    return;
  }
  const candidateId = _.get(eventData, ['Payload', 'CandidateId']);
  const recommendedInfo = _.get(eventData, ['Payload', 'RecommendedInfo'], {});
  if (candidateId && jobId.toString() === currentJobId.toString()) {
    dispatch(updateCandidateRecommendedInfo(candidateId, recommendedInfo, jobId));
  }
  handleSubsegmentShortlistedToJobCandidateRecommended(eventData);
}
export const handleSubsegmentShortlistedToJobCandidateRecommended = eventData => {
  const currentStore = store.getState();
  const featureToggleList = getFeatureToggleList(currentStore) || {};
  const { shouldNavigateToSubsegment, targetJobId: currentJobId } = getShouldNavigateToSubsegmentRedirectionTab(
    window.location,
    featureToggleList
  );
  if (!shouldNavigateToSubsegment || !currentJobId) return;
  const candidateId = _.get(eventData, ['Payload', 'CandidateId']);
  const recommendedInfo = _.get(eventData, ['Payload', 'RecommendedInfo'], {});
  const jobId = _.get(eventData, ['Payload', 'JobId']);
  if (candidateId && jobId.toString() === currentJobId.toString())
    saveCandidateStatus({ candidateId, status: recommendedInfo.Status, jobId });
};

function handleDeletedScoutingAgentNotification(dispatch, eventData) {
  const currentStore = store.getState();
  const currentJobId = getCurrentJobId(currentStore);
  const jobId = _.get(eventData, ['Payload', 'JobId']);
  if (!jobId || !currentJobId) {
    return;
  }
  const agentId = _.get(eventData, ['Payload', 'AgentId']);
  const isDeleted = _.get(eventData, ['Payload', 'IsDeleted'], false);
  if (jobId.toString() === currentJobId.toString() && isDeleted) {
    dispatch(updateScoutingAgentStats(agentId, jobId));
  }
}

const maskingRegex = /^\*+$/;
function isNameMasked(inputString = '') {
  return maskingRegex.test(inputString.trim());
}

function isUniversityMasked(educations = []) {
  return educations.some(item => maskingRegex.test(item.University));
}

function handleEmailSentNotificationFromArya(dispatch, eventData) {
  const { Payload: payload } = eventData;
  const { EmailConversation, CandidateMetadata, JobMetadata } = payload;
  const { Id: id } = CandidateMetadata || {};
  const { JobId: jobId, JobGuid: jobGuid } = JobMetadata || {};
  const _store = store.getState();
  const currentJobId = getCurrentJobId(_store);

  if (!id || !jobId || !isCandidateDisplayedInList(id, jobId) || currentJobId?.toString() !== jobId?.toString()) return;
  const _conversationId = _.get(EmailConversation, ['ConversationId']);
  const personId = _.get(CandidateMetadata, ['PersonId']);
  const candidateConnectIds = {
    [CandidateMetadata.Id]: {
      ConversationId: _conversationId,
      PersonId: personId,
    },
  };
  dispatch(setCandidateConnectIds(candidateConnectIds));
  if (_conversationId) {
    dispatch(fetchConnectStatus(_conversationId, personId));
  }
  dispatch(fetchMailsByEmailConversationId({ emailConversationId: EmailConversation.Id }));
  dispatch(setCandidateConnectionStatusByCandidateId(id, 'Connected'));
  const currentCandidateId = getCurrentCandidateId(_store);
  const maskingConfig = getMaskingConfig(_store);
  const isMaskingActive = maskingConfig?.IsActive;
  if (isMaskingActive && id === currentCandidateId && currentJobId === jobId) {
    const candidateDetails = getCandidateDetails(_store, id) || {};
    if (isNameMasked(candidateDetails.Name) || isUniversityMasked(candidateDetails.Educations)) {
      const filter = {
        candidateId: id,
        jobId,
      };
      dispatch(fetchCandidateDetails(filter, { refId: jobGuid, Country: JobMetadata.CountryCode }));
    }
  }
}

// Not using this
function handleEmailEventFromConnect(connection, dispatch, eventData) {
  if (eventData.IsTestEmail) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const targetCandidate = Object.values(_.get(_store, ['CandidateReducer', 'ById'], {})).find(
    candidateDetail => candidateDetail.ConversationId === _conversationId
  );
  const candidateId = targetCandidate?.Id;
  if (candidateId) {
    const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId']);
    const _consentstatus = _.get(_store, [
      'ConnectReducer',
      'ConnectStatuses',
      _personId,
      'SmsStatus',
      'ConsentStatus',
    ]);
    if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
      dispatch(fetchConnectStatus(_conversationId, _personId));
    }
    dispatch({
      type: 'ADD_NEW_MAIL',
      payload: {
        conversationId: _conversationId,
        Mail: eventData,
      },
    });
    dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
  }
}

export function handleCandidatePushedEvent(eventData) {
  return dispatch => {
    const { VaultCandidateId, Id, VaultName, IsUnlocked, JobId } = eventData.Payload;
    const state = store.getState();
    const currentJobId = JobId || getCurrentJobId(state);
    if (isCandidateDisplayedInList(Id, currentJobId)) {
      const vaultCandidate = {
        Id,
        VaultCandidateId,
      };
      dispatch(addVaultCandidateIds(VaultName, [vaultCandidate], currentJobId));
      if (JobId) {
        const candidateDetails = getCandidateDetails(state, Id);
        if (IsUnlocked && !candidateDetails.IsUnlocked) {
          const jobDetails = getCurrentJobDetails(state);
          const pushCandidateInfo = { refId: jobDetails?.JobGuid, Country: jobDetails?.CountryCode };
          dispatch(
            fetchCandidateDetails(
              {
                jobId: JobId,
                candidateId: Id,
                Id,
              },
              pushCandidateInfo,
              candidateDetails.IsQuickSearchCandidate,
              false
            )
          );
        }
      }
      dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
    }
  };
}

function handleAnnouncementEvent(dispatch, eventData) {
  const announcement = _.get(eventData, 'Announcement', {});
  if (announcement.Type === 'ReleaseAnnouncement') {
    dispatch(
      announcementActionCreator.setReleaseAnnouncements([
        {
          _id: eventData.AnnouncementId,
          Payload: announcement.Payload,
        },
      ])
    );
  }

  if (announcement.Type === 'DowntimeAnnouncement') {
    dispatch(
      announcementActionCreator.setDowntimeAnnouncements([
        {
          _id: eventData.AnnouncementId,
          Payload: announcement.Payload,
        },
      ])
    );
  }
}

async function handleNotificationEvent(socket, dispatch, eventType, eventData) {
  let jobOrSegmentSharedNotifications;
  let senderIds;
  let replaceNotificationAction;
  let jobId;
  let jobGuid;
  let candidateId;
  let fetchContactStatus;
  let currentJobId;
  let personId;
  let conversationId;
  let countryCode;
  const currentStore = store.getState();
  const isNotifcationFetchEnabled = getIsNotifcationFetchEnabled(currentStore);
  const aryaNotificationTypes = [
    'JobShare',
    'JobUnshare',
    'VaultJobFetched',
    'VaultJobNotFound',
    'Sourcing',
    'DilatedSourcing',
    'CandidatesPublished',
    'OrderPlaced',
    'BulkContactFetched',
    'CreditsExhausted',
    'ReportGenerated',
    'ReportGenerationFailed',
    'FindingCandidates',
    'SegmentShare',
    'SegmentUnshare',
    'PortalCredentialFailed',
    'Scoring',
    'CandidateAddRequest-Processed',
    'ChatMessageReceived',
    'ChatMessageReplyReceived',
    'JobUsageBudgetRequestStatus',
    'JobUsageBudgetExhausted',
  ];
  switch (eventType) {
    case 'notification':
      if (eventData.Source === 'Connect' && ['Email', 'SMS', 'Chat'].includes(eventData.Type)) {
        if (isNotifcationFetchEnabled) {
          socket.emit('GetNotifications', {
            Source: 'Connect',
            Types: ['SMS', 'Chat', 'Email'],
          });
        }
        dispatch({
          type: 'NEW_NOTIFICATION_STATUS',
          payload: {
            senderId: eventData.Payload.SenderId,
            showNotification: true,
            notificationType: 'Connect',
          },
        });
      } else if (eventData.Source === 'Arya') {
        if (aryaNotificationTypes.includes(eventData.Type)) {
          if (isNotifcationFetchEnabled) {
            socket.emit('GetNotifications', {
              Source: 'Arya',
            });
          }
          dispatch({
            type: 'NEW_NOTIFICATION_STATUS',
            payload: {
              senderId: eventData.Payload.SenderId,
              showNotification: true,
              notificationType: 'Arya',
            },
          });
          if (eventData.Type === 'CandidatesPublished') {
            dispatch(OrderActions.fetchJobsOrderDetails({ jobIds: [eventData.Payload.JobId] }));
            dispatch(setCandidatesPublishedNotification(eventData.Payload.JobId));
          }
          if (eventData.Type === 'FindingCandidates') {
            dispatch(OrderActions.fetchJobsOrderDetails({ jobIds: [eventData.Payload.JobId] }));
          }
        }
        if (eventData.Type === 'FeaturesUpdated') {
          await dispatch(fetchConfig());
        }

        if (eventData.Type === 'Sourcing' || eventData.Type === 'DilatedSourcing') {
          dispatch(setSourcingNotification(eventData.Payload.JobId));
          await dispatch(fetchJobDilatedIntel(eventData.Payload.JobId));
        }
        if (eventData.Type === 'Scoring') {
          dispatch(setScoringStatus(eventData.Payload.JobId, false, 'notification'));
        }
        if (eventData.Type === 'CandidateAddRequest-ProgressStats') {
          dispatch(
            setCandidatesRecommendationStatus({
              jobId: eventData.Payload.JobId,
              data: eventData.Payload.AdditionStats,
            })
          );
          if (eventData.Payload.RecommendedStats.IsFirstOrEndNotification) {
            dispatch(setBulkShortlistedNotification(eventData.Payload.JobId));
          }
          if (eventData.Payload.RecommendedStats?.RecommendedStatsByStatus) {
            const recommendedStatsByStatus = eventData.Payload.RecommendedStats.RecommendedStatsByStatus;
            for (const [status, count] of Object.entries(recommendedStatsByStatus)) {
              const action = {
                jobId: eventData.Payload.JobId,
                bucketCount: count,
                status,
              };
              dispatch(updateSourcingStats(action));
            }
          }
        }
        if (
          eventData.Type === 'BulkShortlisted' ||
          eventData.Type === 'BulkRejected' ||
          eventData.Type === 'BulkTransferCompleted'
        ) {
          setTimeout(() => {
            dispatch(setBulkShortlistedNotification(eventData.Payload.JobId));
          }, 5000);
        }
        if (eventData.Type === 'BulkContactFetched') {
          ({ JobId: jobId } = eventData.Payload);
          currentJobId = getCurrentJobId(currentStore);
          if (currentJobId && jobId?.toString() === currentJobId.toString()) {
            const currentPageCandidateIds = Object.keys(_.get(currentStore, ['CandidateReducer', 'ById'], {}));
            dispatch(setBulkContactStatus(currentPageCandidateIds));
          }
        }
        if (eventData.Type === 'RecommendedStatusChanged') {
          const { Sourced, Rejected, JobTitle } = eventData.Payload;
          ({ JobId: jobId } = eventData.Payload);
          currentJobId = getCurrentJobId(currentStore);
          if (currentJobId && jobId?.toString() === currentJobId?.toString()) {
            let messageId;
            let mergeTags;
            if (Rejected) {
              messageId = `RejectedMoreCandidatesAreRejectedForJobTitle`;
              mergeTags = { Rejected, JobTitle };
            } else if (Sourced) {
              messageId = `SourcedMoreCandidatesAreUnrejectedForJobTitle`;
              mergeTags = { Sourced, JobTitle };
            } else {
              messageId = 'noSimilarCandidatesFound';
            }
            dispatch(
              setNotification('SUCCESS', {
                messageId,
                mergeTags,
              })
            );
            dispatch(setRecommendedStatusChangedNotification(eventData.Payload.JobId));
          }
        }

        if (eventData.Type === 'ContactUnavailable' && isCandidateDisplayedInList(eventData.Payload.CandidateId)) {
          currentJobId = getCurrentJobId(currentStore);
          jobId = eventData.Payload.JobId;
          personId = eventData.Payload.PersonId;
          if (!jobId || !currentJobId) {
            return;
          }
          dispatch(setFetchContactStatus(eventData.Payload.CandidateId, 'Completed', jobId));
          dispatch({
            type: 'SET_CANDIDATE_CONNECT_INFO',
            payload: {
              connectInfo: {
                [personId]: {
                  Contact: {
                    RequestStatus: 'Success',
                    ConsentStatus: 'Pending',
                  },
                },
              },
            },
          });
        }

        if (eventData.Type === 'ContactNotFound' && isCandidateDisplayedInList(eventData.Payload.CandidateId)) {
          const aryaVersion = _.get(getConfig(currentStore), 'SubscriptionType');
          candidateId = eventData.Payload.CandidateId;
          currentJobId = getCurrentJobId(currentStore);
          jobId = eventData.Payload.JobId;
          personId = eventData.Payload.PersonId;
          jobGuid = getJobGuidFromJobId(currentStore, jobId);
          if (candidateId && personId) {
            dispatch(setCandidatePersonId(candidateId, personId, jobId));
          }
          if (personId) await dispatch(fetchConnectStatuses(jobGuid, [personId]));
          dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
          if (!jobId || !currentJobId) {
            return;
          }
          if (aryaVersion !== 'Lite' && eventData.Payload.Action === 'CANDIDATE_PUSH') {
            dispatch(
              setNotification('ERROR', {
                messageId: 'pushCandidateFailedAsSpecifiedRequirementDidNotMatch',
              })
            );
            dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
          } else if (eventData.Payload.Action === 'CONTACT_FETCH' && aryaVersion === 'Lite') {
            personId = eventData.Payload.PersonId;
            candidateId = eventData.Payload.Id;
            jobId = eventData.Payload.JobId;
            jobGuid = getJobGuidFromJobId(currentStore, jobId);
            fetchContactStatus = getCandidateFetchContactStatus(currentStore, candidateId);
            if (fetchContactStatus === 'Completed' || fetchContactStatus === 'Failed') {
              return;
            }
            if (candidateId && jobId.toString() === currentJobId.toString()) {
              dispatch(setCandidatePersonId(candidateId, personId, jobId));
              dispatch(fetchConnectStatuses(jobGuid, [personId])).then(() => {
                dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
              });
            }
          }
        }
        if (
          eventData.Type === 'RecommendedInfoUpdated' &&
          (isCandidateDisplayedInList(
            _.get(eventData, ['Payload', 'CandidateId']),
            _.get(eventData, ['Payload', 'JobId'])
          ) ||
            isCandidateExtraInfoExists(_.get(eventData, ['Payload', 'CandidateId'])))
        ) {
          handleCandidateRecommendedNotification(dispatch, eventData);
        }
        if (eventData.Type === 'DeletedScoutingAgentNotification') {
          handleDeletedScoutingAgentNotification(dispatch, eventData);
        }
        if (
          eventData.Type === 'BookmarkInfoUpdated' &&
          (isCandidateDisplayedInList(
            _.get(eventData, ['Payload', 'CandidateIds'])[0],
            _.get(eventData, ['Payload', 'JobId'])
          ) ||
            isCandidateExtraInfoExists(_.get(eventData, ['Payload', 'CandidateIds'])[0]))
        ) {
          const { JobId, IsFavourite } = eventData.Payload;
          const _candidateId = _.get(eventData, ['Payload', 'CandidateIds'])[0];
          dispatch(setCandidateFavouriteStatus({ JobId, candidateId: _candidateId, isFavourite: IsFavourite }));
        }
        if (eventData.Type === 'CandidatePushed') {
          dispatch(handleCandidatePushedEvent(eventData));
        }
        if (eventData.Type === 'BulkCandidatesPushed') {
          const { VaultName, VaultCandidates, JobId } = eventData.Payload;
          dispatch(addVaultCandidateIds(VaultName, VaultCandidates, JobId));
        }
        if (eventData.Type === 'EmailSent') {
          handleEmailSentNotificationFromArya(dispatch, eventData);
        }
        if (eventData.Type === 'ContactFetched' && isCandidateDisplayedInList(_.get(eventData, ['Payload', 'Id']))) {
          personId = eventData.Payload.PersonId;
          jobId = eventData.Payload.JobId;
          jobGuid = getJobGuidFromJobId(currentStore, jobId);
          conversationId = eventData.Payload.ConversationId;
          currentJobId = getCurrentJobId(currentStore);
          candidateId = eventData.Payload.Id;
          fetchContactStatus = getCandidateFetchContactStatus(currentStore, candidateId);
          jobId = eventData.Payload.JobId;
          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          if (candidateId && personId) {
            dispatch(setCandidatePersonId(candidateId, personId, jobId));
            dispatch(setCandidateDownloadedDetails(candidateId, true, undefined, jobId));
          }
          if ((conversationId && candidateId) || (jobGuid && personId)) {
            try {
              if (conversationId) await dispatch(fetchConnectStatus(conversationId, personId));
              else await dispatch(fetchConnectStatuses(jobGuid, [personId]));
              dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
            } catch {
              dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
            }
          }
        }
        if (eventData.Type === 'WorkflowInitiated') {
          const {
            Payload: { CandidateId, JobId },
          } = eventData;
          dispatch({
            type: 'WORKFLOW_INITIATED',
            payload: {
              candidateId: CandidateId,
              jobId: JobId,
            },
          });
        }
        if (eventData.Type === 'ImpersonationUpdated') {
          const {
            Payload: { Reload, OrgId },
          } = eventData;
          dispatch({
            type: 'IMPERSONATION_UPDATED',
            payload: {
              reload: Reload,
              orgId: OrgId,
            },
          });
        }
        if (
          (eventData.Type === 'NotesUpdated' || eventData.Type === 'CallNotesUpdated') &&
          isCandidateDisplayedInList(eventData.Payload.CandidateId)
        ) {
          jobId = eventData.Payload.JobId;
          conversationId = eventData.Payload.ConversationId;
          currentJobId = getCurrentJobId(currentStore);
          candidateId = eventData.Payload.CandidateId;
          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          const fetchNotesPayload = {
            candidateId: eventData.Payload.CandidateId,
            jobId: eventData.Payload.JobId,
          };
          if (eventData.Type === 'NotesUpdated') dispatch(fetchCandidateNotes(fetchNotesPayload));
          if (eventData.Type === 'CallNotesUpdated' && conversationId)
            dispatch(fetchCallNotes(candidateId, conversationId, 0, -1));
        }
        if (
          eventData.Type?.toLowerCase() === 'resumegenerated' &&
          eventData.Payload.CandidateId === getCurrentCandidateId(currentStore)
        ) {
          currentJobId = getCurrentJobId(currentStore);
          const jobsById = getJobsById(currentStore);
          jobGuid = currentJobId ? jobsById[currentJobId].JobGuid : null;
          countryCode = currentJobId ? jobsById[currentJobId].CountryCode : null;
          const filter = {
            candidateId: eventData.Payload.CandidateId,
            jobId: currentJobId,
          };
          dispatch(fetchCandidateDetails(filter, { refId: jobGuid, Country: countryCode }));
        }
        if (eventData.Type === 'ResumeUnlocked' && eventData.Payload.Id === getCurrentCandidateId(currentStore)) {
          jobId = eventData.Payload.JobId;
          candidateId = eventData.Payload.Id;
          countryCode = eventData.Payload.CountryCode;
          currentJobId = getCurrentJobId(currentStore);
          if (!jobId || !currentJobId) {
            return;
          }
          if (jobId.toString() !== currentJobId.toString()) {
            return;
          }
          const unlockResumePayload = {
            candidateId,
            jobId,
            countryCode,
          };
          dispatch(broadcastEventActions.unlockCandidateResume(unlockResumePayload));
        }
      }
      break;
    case 'GetNotificationsResponse':
      replaceNotificationAction = getReplaceNotificationAction(eventData.Source, eventData.Response);
      if (eventData.Source?.toUpperCase() === 'CONNECT' && eventData.Response) {
        fetchJobsOnEmailNotificationReceive(eventData.Response, dispatch);
      }
      if (replaceNotificationAction) {
        dispatch(replaceNotificationAction);
      }
      jobOrSegmentSharedNotifications = eventData.Response.Notifications.filter(
        notification => notification.Type === 'JobShare' || notification.Type === 'SegmentShare'
      );
      senderIds = jobOrSegmentSharedNotifications.map(notification => notification.SenderId);
      if (senderIds.length) {
        dispatch(AryaNotifyActions.fetchUsersDetails(senderIds));
      }
      break;
    case 'NotificationsRead':
      replaceNotificationAction = getReplaceNotificationAction(eventData.Source);
      if (replaceNotificationAction) {
        dispatch(replaceNotificationAction);
      }
      break;
    case 'NotificationRead':
      dispatch({
        type: 'MARK_NOTIFICATION_READ',
        payload: {
          NotificationId: eventData.NotificationId,
          notificationType: eventData.Source,
        },
      });
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled notification event', eventData);
      break;
  }
}

function handleChatEvent(connection, dispatch, eventType, eventData) {
  if (eventData.IsTestMessage) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId'], undefined);
  const _consentstatus = _.get(
    _store,
    ['ConnectReducer', 'ConnectStatuses', _personId, 'SmsStatus', 'ConsentStatus'],
    undefined
  );
  const currentJobId = getCurrentJobId(_store);
  const targetCandidate = Object.values(_.get(_store, ['CandidateReducer', 'ById'], {})).find(
    candidateDetail => candidateDetail.ConversationId === _conversationId
  );
  const isChatBotDemo = _store.FeatureToggleReducer?.ChatBotDemo?.IsEnabled ?? false;
  const candidateId = targetCandidate?.Id;
  if (
    !isCandidateDisplayedInList(candidateId, currentJobId) &&
    !checkConversationPopupVisiblity(_store, _conversationId)
  ) {
    return;
  }
  const jobsById = getJobsById(_store);
  const jobGuid = currentJobId ? jobsById[currentJobId].JobGuid : null;
  const countryCode = currentJobId ? jobsById[currentJobId].CountryCode : null;
  const filter = {
    candidateId,
    jobId: currentJobId,
  };
  const currentCandidateId = getCurrentCandidateId(_store);
  const maskingConfig = getMaskingConfig(_store);
  const isMaskingActive = maskingConfig?.IsActive;
  switch (eventType) {
    case 'chat':
      dispatch({
        type: 'ADD_CHAT_MESSAGE',
        payload: {
          conversationId: _conversationId,
          Message: eventData,
        },
      });
      if (candidateId) {
        dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
      }
      break;
    case 'sms':
      if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
        dispatch(fetchConnectStatus(_conversationId, _personId));
      }
      dispatch({
        type: 'ADD_NEW_MESSAGE',
        payload: {
          conversationId: _conversationId,
          Message: eventData,
          isChatBotDemo,
        },
      });
      if (candidateId) {
        dispatch(setCandidateConnectionStatusByCandidateId(candidateId, 'Connected'));
        if (candidateId === currentCandidateId && isMaskingActive) {
          const candidateDetails = getCandidateDetails(_store, candidateId) || {};
          if (isNameMasked(candidateDetails.Name) || isUniversityMasked(candidateDetails.Educations))
            dispatch(fetchCandidateDetails(filter, { refId: jobGuid, Country: countryCode }));
        }
      }
      break;

    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled notification event', eventData);
      break;
  }
}

function handleV2ChatEvent(connection, dispatch, eventData) {
  if (eventData.IsTestMessage) {
    return;
  }
  const message = eventData?.Message;

  const url = window.location.pathname;
  if (!url.endsWith('sourcingsupport')) {
    dispatch(addUnreadCountForChat({ chatId: message?.ChatId, messageId: message?.Id, unreadMessageCount: 1 }));
  }
  if (eventData?.Message?.IsThread) {
    dispatch(addThreadToTheChat({ thread: message }));
  } else {
    dispatch(addReplyToTheThread({ reply: message }));
  }
}
function handleEmailEvent(connection, dispatch, eventData) {
  if (eventData.IsTestEmail) {
    return;
  }
  const _conversationId = eventData.ConversationId;
  const _store = store.getState();
  const _personId = _.get(_store, ['ConnectReducer', 'conversationsById', _conversationId, 'PersonId'], undefined);
  const _consentstatus = _.get(
    _store,
    ['ConnectReducer', 'ConnectStatuses', _personId, 'SmsStatus', 'ConsentStatus'],
    undefined
  );
  if (_conversationId && (_consentstatus === 'Pending' || !_personId)) {
    dispatch(fetchConnectStatus(_conversationId, _personId));
  }
  dispatch({
    type: 'ADD_NEW_MAIL',
    payload: {
      conversationId: _conversationId,
      Mail: eventData,
    },
  });
}

export default {
  handleAnnouncementEvent,
  handleNotificationEvent,
  handleChatEvent,
  handleV2ChatEvent,
  handleEmailEvent,
  handleEmailSentNotificationFromArya,
  handleEmailEventFromConnect,
};
