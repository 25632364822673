const config = {
  appId: 'd31f44fd-11f3-43ba-a378-7b09df3bf900',
  iAryaApiUrl: 'https://qa.leoforce.com/IAryaAPI/api',
  apiV1Url: 'https://app-qa.goarya.com/api/v1',
  apiV2Url: 'https://app-qa.goarya.com/api/v2',
  apiV3Url: 'https://app-qa.goarya.com/api/v3',
  apiV4Url: 'https://app-qa.goarya.com/api/v3.1',
  apiV5Url: 'https://app-qa.goarya.com/api/v3.2',
  defaultSelectedPageSize: 10,
  defaultPageSizes: [10, 20],
  urls: {
    aryaPay: 'https://pay-qa.goarya.com/api/v1',
    aryaConnect: 'https://connectapi-qa.goarya.com/api/v1',
    aryaConnectV2Client: 'https://connect-qa.goarya.com/v2',
    aryaCalendar: 'https://calendar-qa.goarya.com',
    aryaConnectCandidatePage: 'https://connect-qa.goarya.com/chat',
    aryaConnectNotifications: 'https://connectapi-qa.goarya.com/hubs/notification',
    aryaConnectChat: 'https://connectapi-qa.goarya.com/hubs/connect',
    aryaConnectChatV2: 'https://connectapi-qa.goarya.com/hubs/v2/connect',
    aryaConnectApiV2Client: 'https://connectapi-qa.goarya.com/api/v2',
    aryaNotifySocketEndpoint: 'https://notify-qa.goarya.com',
    aryaNotify: 'https://notify-qa.goarya.com/api/v1',
    knowledgeBase: 'https://knowledgebase-qa.goarya.com',
    pulseHostName: 'pulse-qa.goarya.com',
    getWhiteLabelResourceUrl: client => `https://arya3-whitelabel.s3.amazonaws.com/${client.toLowerCase()}-qa`,
    smartjob: 'https://jobsapi.goarya.com/api/v1',
    atlasApiUrl: 'https://atlas-qa.goarya.com/api/v1',
    pulseMarketingUrl: 'https://goarya.com/arya-pulse-testing-page/',
    getAppConfiguration: appType =>
      `https://arya3-whitelabel.s3.amazonaws.com/configuration-qa/settings-${appType}.json`,
    botSummaryViewUrl: 'https://pulse-qa.goarya.com/jobs/{0}/candidates?status=connected',
    verifiedRedirectUri: 'https://aryapulse.page.link/get-started',
    appUrl: 'https://app-qa.goarya.com',
    env: 'qa',
    getGtagUrl: key => `https://www.googletagmanager.com/gtag/js?id=${key}`,
    careerportalUrl:
      'https://portal-qa.goarya.com/leoforce/jobs/se/{{jobId}}/candidates/{{candidateId}}/_applicationReview?aTime={{appliedTime}}&r={{rId}}&acId={{aryaCandidateId}}',
    atsUserProfile: 'https://qa.leoforce.com/Leo/Home/UserProfile',
    getSubmitCandidateUrl: (jobGuid, jobName) =>
      `https://portal-qa.goarya.com/leoforce/jobs/${encodeURIComponent(jobName)}/${jobGuid}/_interestSupport`,
    aryaGpt: 'https://aryagpt-qa.goarya.com',
  },
  mycareers: {
    clientId: '147795441DFFC675',
    url: 'https://portal-qa.goarya.com',
  },
  whiteLabelClients: ['PeopleScout', 'Joveo', 'ChurchStreeter'],
  ipstack: {
    url: 'https://api.ipstack.com',
    key: 'ee2475a24d765710e3af3367c3187678',
  },
  auth: {
    cognito: {
      clientId: '11v64u80pnfsjfo6fb85vm3u6h',
      poolId: 'us-east-1_CoBq70Cuo',
      region: 'us-east-1',
      domain: 'aryaidentity-qa.auth.us-east-1.amazoncognito.com',
      redirectUri: 'https://app-qa.goarya.com',
      identityPoolId: 'us-east-1:d844f23e-1352-4e05-a3e7-11d21ca157d9',
      roleArn: 'arn:aws:iam::700190100279:role/Cognito_arya_ui_identity_pool_qa_Auth_Role',
      revokeEndpoint: 'https://auth-qa.goarya.com/oauth2/',
    },
    arya: {
      cookieKey: 'qa',
      loginUrl: 'https://accounts-qa.goarya.com',
    },
  },
  googleAnalytics: {
    key: 'G-MGS0H52ZN8',
    container: 'GTM-M6Z54PT',
  },
  advanceSearchActivity: {
    Api: 'https://qa.leoforce.com/IAryaAPI/api/v3',
  },
  smartKarrot: {
    appId: 'cf1eabda-b946-4746-9ae4-6d703f4310b9',
    variant: 'option-c',
    productId: '2a1ef5ae-8fd5-4fa9-925b-19672a911e6f',
    isEnabled: false,
  },
  partnerstack: {
    key: 'pk_KMY7mMEvJOmd1Qu3wVgqKyfy84k3d20x',
  },
  pivotRootGTMContainer: 'GTM-M6Z54PT',
  sipml: {
    publicIdentity: 'sip:1220@voip.goarya.com',
    privateIdentity: '1220',
    realm: 'voip.goarya.com',
    uri: '1220@voip.goarya.com',
    wsServers: 'wss://voip.goarya.com:443/ws',
    password: 'Le0$)rCe@(*&%$#2018@sPeC',
    displayName: '+19198000110',
    iceServers: [
      {
        urls: 'stun:voip-ice.goarya.com:3478',
      },
      {
        urls: 'turn:voip-ice.goarya.com:3478',
        username: 'aryavoip',
        credential: 'aryavoip@123',
      },
    ],
  },
  email: {
    oauth: {
      google: {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        redirectUri: 'https://connectapi-qa.goarya.com/api/v1/oauth',
        clientId: '911487744901-d4cnur70bmd4nll6vnv8lvgmsmmp9mdq.apps.googleusercontent.com',
      },
      office365: {
        baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        redirectUri: 'https://connectapi-qa.goarya.com/api/v1/oauth/',
        clientId: '05cd6fac-0e02-4f3a-850f-49a91643c3aa',
      },
    },
  },
  portal: {
    oauth: {
      careerBuilderEdge: {
        baseUrl: 'https://auth.careerbuilder.com/connect/authorize?',
        redirectUri: 'https://app-qa.goarya.com/api/v3/oauth',
        clientId: 'Cdcfe834d',
      },
    },
  },
  sso: {
    providers: [
      {
        path: '/lenovo',
        identifier: 'Lenovo',
      },
      {
        path: '/samling',
        identifier: 'Samling',
      },
      {
        path: '/leforce.net',
        identifier: 'Leoforce.Net',
      },
      {
        path: '/adprm',
        identifier: 'adprm',
      },
      {
        path: '/adpwn',
        identifier: 'adpwn',
      },
    ],
  },
  SMTP: {
    Providers: [
      {
        ProviderName: 'Manual',
        AuthScheme: 'Manual',
      },
      {
        ProviderName: 'google',
        AuthScheme: 'OAuth',
      },
    ],
  },
  ReactEmailEditor: { Bucket: 'arya-react-email-editor-qa' },
  Olark: { Id: '1271-114-10-1180' },
  Clarity: { Id: 'lutxbriz9u' },
  trustedResumeResourceDomains: ['cv.cvlibrary.co.uk'],
  charactersPerMessage: 152,
  charactersPerNote: 65535,
  supportEmail: 'support@leoforce.com',
  getRequestCreditsEmailContent: ({ orgName, userName, amount, requestedCreditMessage, orgId }) => ({
    Subject: `Request to add ${requestedCreditMessage} credits from ${orgName}(${orgId}) - Admin level`,
    Body: `Requested ${requestedCreditMessage} credits from: ${orgName}(${orgId}), Requested by User Name: ${userName}, Requested Credits: ${amount}`,
  }),
  consentTemplateBody:
    'Hi {{Candidate.Name}}! Are you interested in exploring {{Job.Title}} opportunity at {{Organization.Name}}? Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities. Thanks, {{Recruiter.FirstName}}, Recruiter',
  consentTemplateName: 'Mandatory Consent Message',
  consentOption:
    'Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities.',
  privacyLink: 'https://goarya.com/data-privacy-policy/',
  termsOfUseLink: 'https://goarya.com/terms-of-use/',
  Workflow: {
    ScratchTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-qa/scratchtemplate.svg',
    DefaultTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-qa/defaulttemplate.svg',
  },
  Product: {
    ProductVariants: [
      {
        Name: 'SMS',
        Id: '4ADF0F1C-1756-11EF-B163-000C29FC7381',
      },
    ],
  },
};

export default config;
