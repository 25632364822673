import { Button, Menu, Dropdown, Icon, message,Form } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { connect, batch } from 'react-redux';
import SearchSavedCriteriaModal from '../HeaderOptions/Modals/SearchSavedCriteriaModal/SearchSavedCriteriaModal';
import SaveSearchCriteriaModal from '../HeaderOptions/Modals/SaveSearchCriteriaModal/SaveSearchCriteriaModal';
import * as candidateActions from '../../../Actions/CandidateActions';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import * as scoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import * as manualSearchCandidateActions from '../../../Actions/ManualSearchCandidateActions';
import * as jobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import { getPageSize } from '../../../Reducers/JobCandidatesTabReducer';
import {
  generateManualSearchPayload,
  generateManualSearchPayloadWithoutPersonalInfo,
  getDefaultFormValuesFromCriteria,
} from '../../../Utils/ManualSearchUtils';
import {
  getManualSearchSelectedCriteriaType,
  getIsReactivationAllowed,
  getManualSearchSelectedCriteriaId,
  getManualSearchCriteriaType,
} from '../../../Reducers/ManualSearchReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getConfig, getPortalsConfig, getConfiguredSources } from '../../../Reducers/ConfigReducer';
import { getJobDetails } from '../../../Reducers/JobReducer';
import { getSourceName } from '../../../Utils/SourceUtils';
import styles from './ManualSearchFormFooter.module.scss';
import { getImpersonatedUserDetails } from '../../../Reducers/UserSessionReducer';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';
import eventTypes from '../../../Analytics/EventTypes';
import { setNotification } from '../../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  pageSize: getPageSize(state),
  impersonatedUser: getImpersonatedUserDetails(state),
  userConfig: getConfig(state),
  selectedAgentIdToViewCriteria: getManualSearchSelectedCriteriaId(state, { jobId: props.jobId }),
  currentJobDetails: getJobDetails(state, props.jobId),
  selectedCriteriaType: getManualSearchSelectedCriteriaType(state, { jobId: props.jobId }),
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  portalsConfig: getPortalsConfig(state),
  sources: getConfiguredSources(state),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  manualSearchCriteriaType: getManualSearchCriteriaType(state),
});

const mapDispatchToProps = {
  fetchManualSearchCandidateList: candidateActions.fetchManualSearchCandidateList,
  setManualSearchPayload: manualSearchActions.setManualSearchPayload,
  setScoutingAgentModalVisibilityStatus: scoutingAgentActions.setScoutingAgentModalVisibility,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  fetchManualSearchCriteriaById: manualSearchActions.fetchManualSearchCriteriaById,
  setManualSearchFormErrorNotification: manualSearchActions.setManualSearchFormErrorNotification,
  setSelectedCriteriaType: manualSearchActions.setSelectedCriteriaType,
  setSelectedCriteriaName: manualSearchActions.setSelectedCriteriaName,
  setManualSearchFormValues: manualSearchActions.setManualSearchFormValues,
  setJobCandidateTabActiveSourceName: jobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
  setAgentIdViewCriteria: manualSearchActions.setSelectedCriteriaId,
  fetchManualSearchAllTabCandidates: manualSearchCandidateActions.fetchManualSearchAllTabCandidates,
  setSelectedScoutingAgentId: scoutingAgentActions.setSelectedScoutingAgentIdAction,
  SetNotification: setNotification,
};

function isNumericString(str) {
  return /^\d+$/.test(str);
}

function ManualSearchFormFooter(props) {
  const {
    form,
    unsavedCriteriaId,
    setManualSearchPayload,
    saveManualSearchCriteria,
    updateManualSearchCriteria,
    advancedSettings,
    sources,
    sourceNamesSelected,
    initalSources,
    jobId,
    jobGuid,
    onSearchCandidates,
    setManualSearchFormErrorNotification,
    fetchManualSearchAllTabCandidates,
    userConfig,
    impersonatedUser,
    setManualSearchFormValues,
    isManualSearchFormMinimized,
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
    fetchManualSearchCriteriaById,
    selectedCriteriaType,
    currentJobDetails,
    setAdvancedSettings,
    setDefaultFormValues,
    setSelectedCriteriaType,
    setIsReactivationAllowedFlag,
    setSelectedCriteriaName,
    setScoutingAgentModalVisibilityStatus,
    isModalVisible,
    setIsModalVisible,
    selectedCriteriaId,
    setSelectedCriteriaId,
    featureToggleList,
    isReactivationAllowed,
    setSelectedScoutingAgentId,
    scoutingAgentConfig,
    selectedAgentIdToViewCriteria,
    setAgentIdViewCriteria,
    manualSearchCriteriaType,
    SetNotification,
    intl,
  } = props;
  function showScoutingAgentModal(e, mode) {
    if (e.domEvent) e.domEvent.stopPropagation();
    else e.stopPropagation();
    setScoutingAgentModalVisibilityStatus(true, mode);
  }
  const showSaveSearchCriteriaModal = e => {
    form.validateFieldsAndScroll(error => {
      if (error) {
        setManualSearchFormErrorNotification({ errorFields: Object.keys(error) },intl);
        return;
      }
      setIsSaveSearchCriteriaModalVisible(true);
    });
  };
  const [criteriaName, setCriteriaName] = React.useState();
  const [criteriaId, setCriteriaId] = React.useState();
  const [isSaveSearchCriteriaFailureModalVisible, setSaveSearchCriteriaFailureModalVisible] = React.useState(false);
  const [isSaveSearchCriteriaModalVisible, setIsSaveSearchCriteriaModalVisible] = React.useState(false);
  const [saveButtonData, setSaveButtonData] = React.useState([
    { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
    { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
  ]);

  React.useEffect(() => {
    if (manualSearchCriteriaType === 'CreateScoutingAgent') {
      setSaveButtonData([
        { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
        { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
      ]);
    }
  }, [manualSearchCriteriaType]);

  React.useEffect(() => {
    if (selectedAgentIdToViewCriteria) {
      setIsModalVisible(true);
    }
  }, [selectedAgentIdToViewCriteria]);

  React.useEffect(() => {
    const buttonAndMenuOption = [
      manualSearchCriteriaType === 'CreateScoutingAgent'
        ? [
            { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
            { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
          ]
        : [
            { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
            { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
          ],
      [
        { text: 'Save as new criteria', onClick: showSaveSearchCriteriaModal },
        { text: 'Update criteria', onClick: e => handleUpdate(e) },
        { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
      ],
      [
        { text: 'Save as new scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
        { text: 'Update scouting agent', onClick: e => showScoutingAgentModal(e, 'update') },
      ],
    ];

    let initialOptions;
    if (!selectedCriteriaType) [initialOptions] = buttonAndMenuOption;
    else if (selectedCriteriaType === 'Criteria') [, initialOptions] = buttonAndMenuOption;
    else [, , initialOptions] = buttonAndMenuOption;
    setSaveButtonData(initialOptions);
  }, [selectedCriteriaType]);

  const setDraftCriteria = criteria => {
    if (unsavedCriteriaId) {
      const criteriaWithoutPersonalInfo = generateManualSearchPayloadWithoutPersonalInfo({ criteria });
      updateManualSearchCriteria({ criteria: criteriaWithoutPersonalInfo, id: unsavedCriteriaId });
    } else saveManualSearchCriteria({ criteria });
  };

  const onSearch = () => {
    form.validateFieldsAndScroll((error, values) => {
      if (error) {
        setManualSearchFormErrorNotification({ errorFields: Object.keys(error) },intl);
        return;
      }
      onSearchCandidates();
      const selectedSources = sourceNamesSelected.map(x => sources.find(y => getSourceName(y) === x)).filter(x => !!x);
      const manualSearchPayload = generateManualSearchPayload({
        formValues: values,
        locationType: values.LocationType,
        activeSearchString: values.ActiveSearchString,
        advancedSettings,
        id: unsavedCriteriaId,
      });
      const filteredManualSearchPayload = {
        ...manualSearchPayload,
        Titles: manualSearchPayload.Titles.filter(x => x.IsPreferred),
        Skills: {
          ...manualSearchPayload.Skills,
          Prefers: manualSearchPayload.Skills.Prefers?.filter(x => x.IsPreferred),
        },
      };
      const sourcesPayload = selectedSources.map(x => ({
        ...x,
        Name: x?.Portal === 'Internal' && !userConfig.ShowVaultName ? undefined : x.Name,
      }));
      const manualSearchCandidatesFetchPayload = {
        ...filteredManualSearchPayload,
        JobId: jobId,
        Sources: sourcesPayload,
        From: 0,
        Size: filteredManualSearchPayload.IsExactTitleMatchRequired ? 10 : 25,
      };
      const allowReordering =
        candidateContext === 'job' || (candidateContext === 'segment' && isNonInternalPortalForSegmentEnabled);
      batch(() => {
        if (!impersonatedUser) {
          const criteria = {
            JobId: jobId,
            IsDraft: true,
            Criteria: { ...manualSearchPayload, Sources: selectedSources },
          };
          setDraftCriteria(criteria);
        }
        const newValues = {
          ...values,
          sourceNamesSelected: values?.sourceNamesSelected || initalSources,
        };
        setManualSearchFormValues({
          manualSearchFormValues: newValues,
          jobId,
        });
        fetchManualSearchAllTabCandidates({
          manualCriteria: manualSearchCandidatesFetchPayload,
          batchesFetched: 1,
          jobId,
          jobGuid,
          allowReordering,
        });
        setManualSearchPayload({
          manualSearchPayload: {
            ...filteredManualSearchPayload,
            Sources: [{ Portal: 'All', Type: 'Primary' }].concat(sourcesPayload),
          },
          jobId,
        });
      });
    });
  };
  const getCriteria = () => {
    const payload = generateManualSearchPayload({
      formValues: form.getFieldsValue(),
      locationType: form.getFieldValue('LocationType'),
      activeSearchString: form.getFieldValue('ActiveSearchString'),
      advancedSettings,
    });
    return {
      JobId: jobId,
      CriteriaName: criteriaName,
      Criteria: { ...payload, Locations: [], Experience: null, Sources: [], Name: null, Email: null, Mobile: null },
    };
  };
  const handleCriteriaModalsVisibility = response => {
    const { isSuccess, code, status, id } = response;
    if (isSuccess && isSaveSearchCriteriaModalVisible) {
      setIsSaveSearchCriteriaModalVisible(false);
      handleSaveCriteriaSuccess();
    } else if (!isSuccess && code === 'CONFLICTING_CANDIDATESEARCHCRITERIA' && status === 409) {
      setIsSaveSearchCriteriaModalVisible(false);
      setSaveSearchCriteriaFailureModalVisible(true);
      setCriteriaId(id);
    }
  };
  const handleUpdate = async e => {
    if (e.domEvent) e.domEvent.stopPropagation();
    else e.stopPropagation();
    const criteria = getCriteria();
    await updateManualSearchCriteria({ criteria, id: criteriaId });
    setSaveSearchCriteriaFailureModalVisible(false);
    handleSaveCriteriaSuccess();
  };

  const handleSaveCriteriaSuccess = () => {
    setSelectedCriteriaName({ jobId, criteriaName });
    SetNotification('SUCCESS', {
      messageId: 'searchCriteriaSavedLabel',
    });
  };
  const handleSaveCriteria = async () => {
    const criteria = getCriteria();
    const response = await saveManualSearchCriteria({ criteria });
    handleCriteriaModalsVisibility(response);
  };
  const onApplyCriteria = async () => {
    try {
      const criteria = await fetchManualSearchCriteriaById({ id: selectedCriteriaId ?? selectedAgentIdToViewCriteria });
      const selectedCriteriaTypeLocal = !isNumericString(selectedCriteriaId) ? 'Agent' : 'Criteria';
      if (!isNumericString(selectedCriteriaId)) setSelectedScoutingAgentId(selectedCriteriaId);
      const defaultFormValues = await getDefaultFormValuesFromCriteria({ criteria, currentJobDetails });
      setDefaultFormValues(defaultFormValues);
      setAdvancedSettings({});
      form.resetFields();
      SetNotification('SUCCESS', {
        messageId: 'criteriaUpdatedLabel',
      });
      setAgentIdViewCriteria({ jobId, criteriaId: null });
      setSelectedCriteriaType({ jobId, selectedCriteriaType: selectedCriteriaTypeLocal });
      setSelectedCriteriaName({ jobId, criteriaName: criteria.CriteriaName });
      setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
    } catch (e) {
      console.log({ manuerr: e });
      SetNotification('ERROR', {
        messageId: 'oopsSomethingJustWentWrong',
      });
    }
    setSelectedCriteriaId(undefined);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCriteriaId(undefined);
  };
  const menu = () => {
    const options = saveButtonData.slice(1, saveButtonData.length);
    return (
      <Menu>
        {options.map(option => (
          <Menu.Item
            key={option.text}
            onClick={e => {
              e.domEvent.stopPropagation();
              option.onClick(e);
            }}
          >
            {option.text}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const handleOk = () => {
    setIsModalVisible(false);
    onApplyCriteria();
  };
  return (
    <div className={`${styles.manualSearchFormFooter} ${isManualSearchFormMinimized ? styles.hideFooter : ''}`}>
      <SearchSavedCriteriaModal isModalVisible={isModalVisible} handleCancel={handleCancel} handleOk={handleOk} />
      <SaveSearchCriteriaModal
        isSaveSearchCriteriaModalVisible={isSaveSearchCriteriaModalVisible}
        handleSaveCriteria={handleSaveCriteria}
        setIsSaveSearchCriteriaModalVisible={setIsSaveSearchCriteriaModalVisible}
        setCriteriaName={setCriteriaName}
        isSaveSearchCriteriaFailureModalVisible={isSaveSearchCriteriaFailureModalVisible}
        setSaveSearchCriteriaFailureModalVisible={setSaveSearchCriteriaFailureModalVisible}
        criteriaName={criteriaName}
        handleUpdate={handleUpdate}
      />
      <div
        className={classNames({
          [styles.manualSearchFormSaveButton]: featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig,
        })}
      >
        {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig ? (
          <Button
            size="large"
            shape="round"
            type="secondary"
            disabled={!isReactivationAllowed}
            onClick={saveButtonData[0].onClick}
            sk-event-name={eventTypes.job.manualSearch.applyManualSearchCriteria}
          >
            {saveButtonData[0].text}
            <Dropdown
              overlay={menu}
              trigger={['click']}
              overlayClassName={styles.saveDropDown}
              overlayStyle={{ minWidth: '250px' }}
              placement="bottomRight"
            >
              <div className={styles.iconStyle} role="presentation" onClick={e => e.stopPropagation()}>
                <Icon type="down" />
              </div>
            </Dropdown>
          </Button>
        ) : (
          <Button
            style={{ marginRight: '12px' }}
            size="large"
            shape="round"
            type="secondary"
            onClick={showSaveSearchCriteriaModal}
          >
            Save Criteria
          </Button>
        )}
      </div>
      <div className={styles.manualSearchFormFooterButtons}>
        <Button
          size="large"
          shape="round"
          type="primary"
          onClick={onSearch}
          sk-event-name={eventTypes.job.manualSearch.applyManualSearchCriteria}
        >
          Search
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(ManualSearchFormFooter)));
export { ManualSearchFormFooter as ManualSearchFormFooterWithoutStore };
