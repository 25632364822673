const SET_AVAILABLE_JOB_USAGE_BUDGET = 'SET_AVAILABLE_JOB_USAGE_BUDGET';

const SET_JOB_USAGE_BUDGET_REQUESTS = 'SET_JOB_USAGE_BUDGET_REQUESTS';

const SET_UPDATED_JOB_USAGE_REQUEST_STATUS = 'SET_UPDATED_JOB_USAGE_REQUEST_STATUS';

const SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST = 'SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST';

export {
  SET_AVAILABLE_JOB_USAGE_BUDGET,
  SET_JOB_USAGE_BUDGET_REQUESTS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
};
