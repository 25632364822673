import { Icon } from 'antd';
import * as React from 'react';

const AryaSourcingIndicatorSvg = props => (
  <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 0C2.23884 0 0 2.23884 0 5C0 7.76116 2.23884 10 5 10C7.76116 10 10 7.76116 10 5C10 2.23884 7.76116 0 5 0ZM5 9.15179C2.70759 9.15179 0.848214 7.29241 0.848214 5C0.848214 2.70759 2.70759 0.848214 5 0.848214C7.29241 0.848214 9.15179 2.70759 9.15179 5C9.15179 7.29241 7.29241 9.15179 5 9.15179ZM7.21429 2.58817C7.17058 2.57109 7.12393 2.56279 7.07701 2.56375C7.03009 2.56471 6.98382 2.5749 6.94085 2.59375L3.91629 3.91741L2.59375 6.94196C2.55357 7.03348 2.55357 7.13728 2.59375 7.2288C2.6317 7.31585 2.70201 7.38393 2.79129 7.41853C2.83371 7.43527 2.87723 7.44308 2.92188 7.44308C2.97098 7.44308 3.01897 7.43304 3.06473 7.41295L6.08929 6.08929L7.41183 3.06473C7.43113 3.02059 7.44131 2.97301 7.44177 2.92484C7.44223 2.87667 7.43296 2.8289 7.41452 2.78439C7.39607 2.73989 7.36883 2.69957 7.33442 2.66586C7.30001 2.63214 7.25915 2.60571 7.21429 2.58817ZM5.72321 5.25L6.01562 5.54241L5.54241 6.01562L5.25 5.72321L3.52679 6.47768L4.28013 4.75446L3.99554 4.46875L4.46875 3.99554L4.75335 4.28013L6.47768 3.52679L5.72321 5.25ZM4.46875 3.99554L3.99554 4.46875L4.28013 4.75446L5.25 5.72321L5.54241 6.01562L6.01562 5.54241L5.72321 5.25L4.75446 4.28013L4.46875 3.99554Z"
      fill={props.fillColor ?? "#13C26B"}
    />
  </svg>
);
const AryaSourcingIndicatorIcon = props => (
  <Icon component={customProps => <AryaSourcingIndicatorSvg {...props} {...customProps} />} {...props} />
);
export default AryaSourcingIndicatorIcon;
