import React from 'react';
import { Icon } from 'antd';

export const CreditsUsedFilterSVG = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.58242V0H16V1.58242L10.5455 6.85714H5.45455L0 1.58242Z" fill="#83878C" />
      <path d="M5.81818 13.8901V7.56044H10.1818V16L5.81818 13.8901Z" fill="#83878C" />
    </svg>
  );
};

const CreditsUsedFilter = props => <Icon component={CreditsUsedFilterSVG} {...props} />;

export default CreditsUsedFilter;
