import config from '../Config/Config';
import { cognitoTokenRevokeClient } from './BaseRepository';

export async function revokeCognitoRefreshToken() {
  const refreshToken = getRefreshToken();
  const body = new URLSearchParams();
  body.append('token', refreshToken);
  body.append('client_id', config.auth.cognito.clientId);
  await cognitoTokenRevokeClient.post("revoke", body);
}

function getRefreshToken() {
  const refreshTokenRegex = /.*Cognito.*\.refreshToken$/;

  // Dev env store tokens in local storage
  const refreshTokenKey = Object.keys(localStorage)
    .find(key => key.match(refreshTokenRegex));
  if (refreshTokenKey) {
    return localStorage.getItem(refreshTokenKey);
  }

  // Hosted env store tokens in cookies
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');
  const refreshTokenCookie = cookies.find(cookie => refreshTokenRegex.test(cookie.split('=')[0]));
  if (refreshTokenCookie) {
    const [, refreshTokenValue] = refreshTokenCookie.split('=');
    return decodeURIComponent(refreshTokenValue);
  }

  console.log("No refresh token found in localStorage");
  return null;
}
