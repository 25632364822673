import React from 'react';
import {  FormattedMessage } from 'react-intl';
import { Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';
import styles from './CandidateSegmentsAndCampaignsItem.module.scss';

function CandidateSegmentsAndCampaignsItem(props) {
  const { segmentName, campaigns, createdBy, createdTime, segmentId, version, openSegmentAtsView } = props;
  const handleOpenSegment = () => {
    if (version !== 'ats') {
      window.open(`/segments/${segmentId}`);
    } else {
      openSegmentAtsView(segmentId);
    }
  };
  return (
    <div className={styles.segmentCardDetails} role="presentation">
      <Row className={styles.segmentCard} type="flex" justify="space-between">
        <Col xs={24} sm={7}>
          <Tooltip title={segmentName}>
            <div
              className={styles.segmentName}
              onClick={handleOpenSegment}
              onKeyPress={handleOpenSegment}
              role="button"
              tabIndex={0}
            >
              {segmentName}
            </div>
          </Tooltip>
        </Col>
        <Col xs={24} sm={7}>
          <div className={campaigns?.length ? styles.campaigns : styles.none}>{campaigns || <FormattedMessage {...messages.None} />}</div>
        </Col>

        <Col xs={24} sm={5}>
          <div className={styles.creatorName}>{createdBy}</div>
          <div className={styles.dateTime}>{moment.utc(createdTime).local().format('hh:mm A | MMM DD, YYYY')}</div>
        </Col>
      </Row>
    </div>
  );
}

export default CandidateSegmentsAndCampaignsItem;
