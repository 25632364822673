import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Form, Icon, Tooltip } from 'antd';
import { UserAlertWithWrapper } from '../UserAlerts/UserAlerts';
import ConnectTemplateSelect from '../JobSettings/ConnectTemplateSelect';
import RichTextEditor from '../Editor/RichTextEditor';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import styles from './EmailAutomation.module.scss';
import message from '../../Containers/CandidateDrawer/messages';
import jobMessage from '../JobForm/JobMessages';

function EmailAutomation(props) {
  const {
    consentComplianceInfo,
    emailTemplateIds,
    emailTemplatesById,
    emailTemplatesCount,
    selectedDripTemplate,
    fetchEmailTemplates,
    isPulse,
    selectedEmailTemplateId,
    selectedEmailTemplateName,
    setDefaultEmailTemplateValues,
    emailTemplates,
    onChangeEmailTemplate,
    emailTemplateContentVisibility,
    toggleEmailContentVisibility,
    selectedEmailTemplateSubject,
    selectedEmailPreHeader,
    ref,
    updateBodyContent,
    selectedEmailTemplateBody,
    selectedEmailTemplateSendTo,
    onEmailSendToChange,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    sendEmailTo,
    isSendToOptionAllowed,
    isEmailAutomationNoteAllowed,
    templateSelectorClassname,
    candidateContext,
  } = props;

  const linkButtonName = emailTemplateContentVisibility ? (
    <FormattedMessage {...jobMessage.hideContentLabel} />
  ) : (
    <FormattedMessage {...jobMessage.seeContentLabel} />
  );
  const isSelectedDripTemplate = selectedDripTemplate && selectedDripTemplate.Drops;
  const isMultipleEmailDrip = isSelectedDripTemplate && selectedDripTemplate.Drops.length > 1;

  const getEmaiAutomationNote = () => {
    return isEmailAutomationNoteAllowed ? (
      <BulkCandidatesDownloadFilterInfo
        candidateDownloadFilterConfig={candidateDownloadFilterConfig}
        candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
        dripTemplate={isSelectedDripTemplate}
      />
    ) : null;
  };
  const linkButton = selectedEmailTemplateName ? (
    <Button type="link" onClick={toggleEmailContentVisibility}>
      <u>{linkButtonName}</u>
    </Button>
  ) : null;
  const multipleEmailDripMessage = isMultipleEmailDrip ? (
    <span>
      +{selectedDripTemplate.Drops.length - 1} <FormattedMessage {...jobMessage.moreDripEmailsInfo} />
    </span>
  ) : null;
  const emailPreHeader =
    selectedEmailPreHeader && !selectedDripTemplate ? (
      <div className={styles.connectTemplateSubject}>
        <Form.Item
          label={
            <span>
              <span style={{ fontWeight: '500' }}>
                <FormattedMessage {...jobMessage.preheaderLabel} />
              </span>
              &nbsp;
              <Tooltip title={<FormattedMessage {...jobMessage.preheaderDescriptionMessage} />}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <RichTextEditor
            className="mail-subject-editor connect-template-subject"
            editorContent={{ htmlContent: selectedEmailPreHeader }}
            showToolbar={false}
            ref={ref}
            disabled
          />
        </Form.Item>
      </div>
    ) : null;
  const getEmailTemplateContent = () => {
    return emailTemplateContentVisibility ? (
      <div>
        <div className={styles.connectTemplateSubject}>
          <Form.Item
            label={
              <span style={{ fontWeight: '500' }}>
                <FormattedMessage {...jobMessage.subjectLabel} />
              </span>
            }
          >
            <RichTextEditor
              className="mail-subject-editor connect-template-subject"
              editorContent={{ htmlContent: selectedEmailTemplateSubject }}
              showToolbar={false}
              ref={ref}
              disabled
            />
          </Form.Item>
        </div>
        {emailPreHeader}
        <div className={styles.connectTemplateBody}>
          <Form.Item
            label={
              <span style={{ fontWeight: '500' }}>
                <FormattedMessage {...jobMessage.bodyLabel} />
              </span>
            }
          >
            <RichTextEditor
              editorContent={{ htmlContent: selectedEmailTemplateBody }}
              onChange={updateBodyContent}
              showAdditionalOptions
              showToolbar={false}
              disabled
            />
          </Form.Item>
        </div>
        <div className={styles.dripInfo}>{multipleEmailDripMessage}</div>
      </div>
    ) : null;
  };
  const getSendToOption = () => {
    return isSendToOptionAllowed ? (
      <div id="auto-email-send-to-container" className={styles.sendToContainer}>
        <div className={styles.sendToLabel}>
          <FormattedMessage {...message.sendToLabel} />
        </div>
        <Checkbox.Group onChange={onEmailSendToChange} value={selectedEmailTemplateSendTo}>
          <Checkbox value={sendEmailTo.shortlistedIdentifier}>
            <FormattedMessage {...message.shortlistedCandidatesLabel} />
          </Checkbox>
          <Checkbox value={sendEmailTo.scoreAndRankIdentifier}>
            <FormattedMessage {...message.scoredAndRankedByMeLabel} />
          </Checkbox>
        </Checkbox.Group>
      </div>
    ) : null;
  };
  return (
    <div>
      {consentComplianceInfo ? (
        <UserAlertWithWrapper header={consentComplianceInfo.header} content={consentComplianceInfo.body} />
      ) : (
        <div>
          <div>
            <div style={{ display: 'flex' }}>
              <ConnectTemplateSelect
                cssClassname={templateSelectorClassname}
                templateIds={
                  candidateContext === 'segment'
                    ? emailTemplateIds.filter(emailTemplateId => !emailTemplatesById[emailTemplateId].IsDrip)
                    : emailTemplateIds
                }
                templatesById={emailTemplatesById}
                templatesCount={emailTemplatesCount}
                dripTemplate={selectedDripTemplate}
                fetchTemplates={fetchEmailTemplates}
                isPusle={isPulse}
                selectedTemplateId={selectedEmailTemplateId}
                selectedTemplateName={selectedEmailTemplateName}
                setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
                emailTemplates={emailTemplates}
                placeholderValue={<FormattedMessage {...jobMessage.selectTemplateRequiredLabel} />}
                onChange={onChangeEmailTemplate}
                candidateContext={candidateContext}
              />
              {linkButton}
            </div>
            {getEmailTemplateContent()}
            {getSendToOption()}
            {getEmaiAutomationNote()}
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailAutomation;
