import React from 'react';
import { Alert } from 'antd';
import {  FormattedMessage } from 'react-intl';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

function MessageWindowTemplateConsentNote() {
  return (
    <Alert message={<FormattedMessage {...messages.consentMessageLabel} />} type="info" showIcon />
  );
}

export default MessageWindowTemplateConsentNote;
