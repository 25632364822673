import { klona as cloneDeep } from 'klona';
import _ from 'lodash';
import {
  SET_AVAILABLE_JOB_USAGE_BUDGET,
  SET_JOB_USAGE_BUDGET_REQUESTS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS,
  SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST,
} from '../Actions/ActionCreators/JobUsageBudgetActionCreator';

const initialState = {
  ById: {},
  CreditRequests: {},
};
function JobUsageBudgetReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_AVAILABLE_JOB_USAGE_BUDGET:
      newState = cloneDeep(state);
      newState.ById[action.payload.JobId] = action.payload;
      return newState;
    case SET_JOB_USAGE_BUDGET_REQUESTS:
      newState = cloneDeep(state);
      newState.CreditRequests = action.payload.Requests.reduce((acc, request) => {
        acc[request.RequestId] = request;
        return acc;
      }, {});
      newState.TotalRequests = action.payload.TotalCount;
      return newState;
    case SET_UPDATED_JOB_USAGE_REQUEST_STATUS:
      newState = cloneDeep(state);
      newState.ById[action.payload.JobId].RequestStatus = action.payload.RequestStatus;
      return newState;
    case SET_UPDATED_JOB_USAGE_REQUEST_STATUS_FOR_REQUEST:
      newState = cloneDeep(state);
      newState.CreditRequests[action.payload.RequestId].RequestStatus = action.payload.RequestStatus;
      newState.CreditRequests[action.payload.RequestId].LastAllocatedBudget = action.payload.LastAllocatedBudget;
      newState.CreditRequests[action.payload.RequestId].CurrentAllocatedBudget = action.payload.CurrentAllocatedBudget;
      return newState;
    default:
      return state;
  }
}

function JobUsageBudgetDataByJobId(state, jobId) {
  return _.get(state, ['JobUsageBudgetReducer', 'ById', jobId], {});
}

function JobUsageBudgetRequests(state) {
  return _.get(state, ['JobUsageBudgetReducer', 'CreditRequests'], {});
}

function JobUsageTotalBudgetRequests(state) {
  return _.get(state, ['JobUsageBudgetReducer', 'TotalRequests'], 0);
}

export { JobUsageBudgetReducer, JobUsageBudgetDataByJobId, JobUsageBudgetRequests, JobUsageTotalBudgetRequests };
