import React, { useMemo } from 'react';
import { Input, Popover, Icon } from 'antd';
import { Grid } from 'react-virtualized';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import CheckboxItem from './CheckboxItem';
import BoooleanSelector from '../../../Components/Common/BooleanSelector/BooleanSelector';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';
import { getGroupedKeywords, getAllTabAggregationParentKeywordCount } from '../../../Utils/CandidateListUtils';

const { Search } = Input;

const Cell = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const { isSearchPerformed } = data;
  const skill = data.skills[rowIndex * data.columnCount + columnIndex];
  const groupedSkill = data.skillsList[rowIndex * data.columnCount + columnIndex];
  if (!isSearchPerformed && !groupedSkill) return null;
  if (isSearchPerformed && !skill) return null;
  const isSkillHighlighted =
    data.sortedAllTabAggregationFilter.Skills[!isSearchPerformed ? groupedSkill.parentSkill : skill].IsHighlighted;
  const { onChange, value: formValue } = data;

  const onChangeSkills = e => {
    const { checked, value } = e.target;
    if (checked) onChange([...formValue, value]);
    else onChange(formValue.filter(item => item !== value));
  };

  const onChangeGroupedSkills = e => {
    const { checked, value } = e.target;
    if (checked) onChange([...formValue, value, ...groupedSkill.childSkills]);
    else onChange(formValue.filter(item => !groupedSkill.childSkills.includes(item) && item !== value));
  };

  const someChildTitlesChecked = groupedSkill?.childSkills?.some(childSkill => formValue.includes(childSkill));

  const parentCheckboxIndeterminate = !formValue.includes(groupedSkill?.parentSkill) && someChildTitlesChecked;

  const childSkillItems = groupedSkill?.childSkills?.map(childSkill => (
    <CheckboxItem
      key={childSkill}
      value={childSkill}
      text={childSkill}
      count={data.sortedAllTabAggregationFilter.Skills[childSkill].CandidateIds?.length || 0}
      isHighlighted={data.sortedAllTabAggregationFilter.Skills[childSkill].IsHighlighted}
      maxTextLength={15}
      onChange={onChangeSkills}
      checked={formValue.includes(childSkill)}
    />
  ));

  const parentKeywordCandidatesCount = !isSearchPerformed
    ? getAllTabAggregationParentKeywordCount(
        data.sortedAllTabAggregationFilter.Skills,
        groupedSkill.parentSkill,
        groupedSkill.childSkills
      )
    : data.sortedAllTabAggregationFilter.Skills[skill].CandidateIds?.length || 0;

  const childSkillItemsContainer = (
    <div
      style={{
        padding: '14px 17px 22px 10px',
        maxHeight: '250px',
        overflowY: 'scroll',
        maxWidth: '300px',
        gap: '5px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {childSkillItems}
    </div>
  );
  return (
    <div style={{ ...style, width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CheckboxItem
        value={!isSearchPerformed ? groupedSkill.parentSkill : skill}
        text={!isSearchPerformed ? groupedSkill.parentSkill : skill}
        count={parentKeywordCandidatesCount}
        isHighlighted={isSkillHighlighted}
        maxTextLength={15}
        onChange={!isSearchPerformed ? onChangeGroupedSkills : onChangeSkills}
        checked={formValue.includes(!isSearchPerformed ? groupedSkill.parentSkill : skill)}
        indeterminate={!isSearchPerformed && parentCheckboxIndeterminate}
      />
      {!isSearchPerformed && childSkillItems?.length > 0 ? (
        <Popover
          content={childSkillItemsContainer}
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.15}
          placement="bottom"
          trigger={['hover', 'click']}
          autoAdjustOverflow
          getPopupContainer={_ => document.body}
        >
          <Icon type="ordered-list" />
        </Popover>
      ) : null}
    </div>
  );
});

const SkillsFilter = React.memo(
  ({
    sortedAllTabAggregationFilter,
    allTabAggregationFilter,
    form,
    candidatesSkillsOperationField,
    allTabKeywordsOperation,
    candidatesSkillsField,
    allTabSelectedAggregationFilter,
    filterRef,
  }) => {
    const [keywordSearchTerm, setKeywordSearchTerm] = React.useState('');

    const handleKeywordSearchChange = e => {
      setKeywordSearchTerm(e.target.value);
    };

    const columnCount = 4;
    const filteredSkills = React.useMemo(
      () =>
        Object.keys(sortedAllTabAggregationFilter.Skills || {}).filter(skill =>
          skill.toLowerCase().includes(keywordSearchTerm.toLowerCase())
        ),
      [keywordSearchTerm, sortedAllTabAggregationFilter.Skills]
    );
    const isSearchPerformed = keywordSearchTerm?.trim().length > 0 ?? false;

    const skills = filteredSkills;
    const groupedSkills = useMemo(() => {
      return getGroupedKeywords(allTabAggregationFilter.Skills);
    }, [allTabAggregationFilter.Skills]);

    const skillsList = useMemo(() => {
      return Object.entries(groupedSkills).map(([parentSkill, childSkills]) => ({
        parentSkill,
        childSkills,
      }));
    }, [groupedSkills]);
    const rowCount = Math.ceil((isSearchPerformed ? skills.length : skillsList.length) / columnCount);

    const columnWidth = 210;
    const rowHeight = 36;
    const gridHeight = Math.min(rowHeight * rowCount, 200);

    const cellRenderer = ({ columnIndex, rowIndex, style }) => (
      <Cell
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        style={style}
        data={{
          skills,
          skillsList,
          isSearchPerformed,
          sortedAllTabAggregationFilter,
          columnCount,
          onChange: form.getFieldProps(candidatesSkillsField).onChange,
          value: form.getFieldValue(candidatesSkillsField) || [],
        }}
      />
    );

    return (
      <div className={styles.filterSection}>
        <div className={styles.filterSectionHeader}>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <span className={styles.subsectionTitle}>Skills/Keywords</span>
            <Search
              className={styles.searchBox}
              placeholder="Search"
              allowClear
              value={keywordSearchTerm}
              onChange={handleKeywordSearchChange}
            />
          </div>
          {form.getFieldDecorator(candidatesSkillsOperationField, { initialValue: allTabKeywordsOperation })(
            <BoooleanSelector />
          )}
          <LinkButton
            buttonName="Clear all"
            onClickButton={() => {
              form.getFieldProps(candidatesSkillsField).onChange([]);
            }}
            style={{ color: 'rgba(7, 16, 26, 0.5)' }}
          />
        </div>
        {form.getFieldDecorator(candidatesSkillsField, {
          initialValue: allTabSelectedAggregationFilter.Skills,
        })(
          <Grid
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={gridHeight}
            rowCount={rowCount}
            rowHeight={rowHeight}
            width={840}
            cellRenderer={cellRenderer}
            style={{ overflowX: 'hidden', maxHeight: '200px' }}
            ref={filterRef}
          />
        )}
      </div>
    );
  }
);

export default SkillsFilter;
